<script setup>
import SvgView31 from "@/views/home/svg/SvgView31.vue";
import SvgView32 from "@/views/home/svg/SvgView32.vue";
import SvgView33 from "@/views/home/svg/SvgView33.vue";
import SvgView34 from "@/views/home/svg/SvgView34.vue";
import SvgView35 from "@/views/home/svg/SvgView35.vue";
import SvgView36 from "@/views/home/svg/SvgView36.vue";
import AnimationBox from "@/views/home/component/AnimationBox.vue";
import { ref, onMounted } from "vue";
const parentRef = ref(null);
const AnimationBoxRef1 = ref(null);
const AnimationBoxRef2 = ref(null);
const AnimationBoxRef3 = ref(null);
const AnimationBoxRef4 = ref(null);
const AnimationBoxRef5 = ref(null);
const AnimationBoxRef6 = ref(null);

const parentInfo = ref({ width: 0, height: 0 });
const render = ref(true);

const showRun = (type = false) => {
  AnimationBoxRef1.value.init(parentInfo.value, type);
  AnimationBoxRef2.value.init(parentInfo.value, type);
  AnimationBoxRef3.value.init(parentInfo.value, type);
  AnimationBoxRef4.value.init(parentInfo.value, type);
  AnimationBoxRef5.value.init(parentInfo.value, type);
  AnimationBoxRef6.value.init(parentInfo.value, type);
};

const getBoxInfo = () => {
  parentInfo.value = {
    width: parentRef.value.offsetWidth,
    height: parentRef.value.offsetHeight,
  };
};

window.onresize = () => {
  getBoxInfo();
  showRun(true);
  // reload();
};

// const reload = () => {
//   // 重新渲染
//   render.value = false;
//   nextTick(() => {
//     render.value = true;
//   });
// };

onMounted(() => {
  getBoxInfo();
  showRun(false);
});
</script>
<template>
  <div ref="parentRef" v-if="render" class="SvgAnimation_root d-flex">
    <AnimationBox ref="AnimationBoxRef1" :parentInfo="parentInfo">
      <SvgView31></SvgView31>
    </AnimationBox>
    <AnimationBox ref="AnimationBoxRef2" :parentInfo="parentInfo">
      <SvgView32></SvgView32>
    </AnimationBox>
    <AnimationBox ref="AnimationBoxRef3" :parentInfo="parentInfo">
      <SvgView33></SvgView33>
    </AnimationBox>
    <AnimationBox ref="AnimationBoxRef4" :parentInfo="parentInfo">
      <SvgView34></SvgView34>
    </AnimationBox>
    <AnimationBox ref="AnimationBoxRef5" :parentInfo="parentInfo">
      <SvgView35></SvgView35>
    </AnimationBox>
    <AnimationBox ref="AnimationBoxRef6" :parentInfo="parentInfo">
      <SvgView36></SvgView36>
    </AnimationBox>
  </div>
</template>
<style scoped lang="less">
.SvgAnimation_root {
  height: 100%;
  position: relative;
}
</style>
