export default {
  home: "الصفحة الرئيسية",
  about: "حول",
  document: "مستند",
  t1: "引入去中心化通用計算的",
  t2: "بلوك تشين حوسبة الأغراض العامة للمؤسسات",
  t3: "أول شبكة لا مركزية من معالجات حوسبة الأغراض العامة المعيارية المشتركة للأغراض العامة في إيثرنت",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "تلبية احتياجات الحوسبة للتطبيقات على مستوى المؤسسات",
  t10: "شبكة حوسبة لا مركزية لا مركزية للأغراض العامة أسرع وأقوى وأكثر أماناً",
  t11: "يتمتع المطورون والمؤسسات بحرية بناء ونشر تطبيقات عالية الأداء.",
  t12: "أسرع من السرعة",
  t13: "سرعة تحقق تصل إلى 300 مللي ثانية للتحقق من صحة الكتل، وسرعة تصل إلى 20,000 في الثانية",
  t14: "تكاليف حوسبة منخفضة",
  t15: "يقلل حل الحوسبة اللامركزية واسعة النطاق خارج السلسلة بشكل فعال من تكلفة قوة الحوسبة للمؤسسات.",
  t16: "أمان خصوصية على مستوى المؤسسات",
  t17: "تتم حماية خصوصية المؤسسات والحسابات الحساسة من خلال الحوسبة خارج السلسلة.",
  t18: "قدرة الحوسبة العامة",
  t19: "مع حلول توسيع نطاق أكثر تقدماً، يمكن لشبكة Ethernet أيضاً تلبية احتياجات الحوسبة لتطبيقات المؤسسات",
  t20: "لا مركزية وشفافة",
  t21: "أول شبكة لا مركزية من الطبقة الثانية في إيثرنت تقدم طبقة توافق في الآراء",
  t22: "التوافق وقابلية التوسعة",
  t23: "يدعم التوافق مع إيثرنت من النوع 1، بالإضافة إلى جميع الوظائف والميزات المتقدمة للغة التطوير القائمة على zkVM.",
  t24: "بنية ENIAC الأساسية",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "وحدات التطبيق الأساسية القائمة على بنية ENIAC",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "التعلم الآلي للمعرفة الصفرية ",
  t38: "إثبات المعرفة الصفرية مع التعلم الآلي لتحسين الكفاءة مع حماية خصوصية البيانات وموثوقيتها في الوقت نفسه.",
  t39: "ميتافيرس المعرفة الصفرية اللامركزية",
  t40: "يزود المستخدمين بهوية رقمية موثوق بها استناداً إلى شبكة لا مركزية ومصادقة المعرفة الصفرية.",
  t41: "الهوية اللامركزية المعرفة الصفرية اللامركزية",
  t42: "تزويد المستخدمين بهوية رقمية موثوق بها تستند إلى شبكة لا مركزية ومصادقة بدون معرفة.",
  t43: "الرول آب كخدمة ",
  t44: "يدعم النشر السريع لـ Rollup للمؤسسات استناداً إلى zkVM.",
  t45: "استكشف ENIAC",
  t46: "استكشاف الاحتمالات غير المحدودة للحوسبة اللامركزية للأغراض العامة",
  t47: "ل حلول تطبيقات البلوك تشين الموحدة على مستوى المؤسسات",
  t48: "النشر السريع، عند الطلب للشركات الناشئة",
  t49: "ل منتجات وخدمات بلوك تشين المؤسساتية المعيارية والمخصصة بسرعة",
  t50: "للشركات الصغيرة والمتوسطة، خيارات مرنة وتكيف سريع",
  t51: "ل التخصيص المتعمق القائم على AppRollups",
  t52: "بالنسبة للمؤسسات الكبيرة والكبيرة جداً، مجموعة كاملة من التخصيص المتعمق",
  t53: "خصوصية وأمان تعاون بيانات المؤسسة التعاونية",
  t54: "تدعم ENIAC الاحتفاظ الكامل بالبيانات السرية بين الشركات مع إنجاز تفاعلات البيانات وبيانات التعاون التجاري في السلسلة.",
  t55: "إدارة سلسلة التوريد",
  t56: "من خلال ENIAC، يمكن للمؤسسات بناء نظام موثوق لإدارة سلسلة التوريد بسرعة دون الكشف عن التفاصيل، ببساطة التحقق من صحة نتائج بيانات الروابط الهامة في السلسلة.",
  t57: "التمويل والمنتجات المبتكرة",
  t58: "تدعم شركة ENIAC العملاء لتطوير خدمات مالية جديدة، مثل تقييم الائتمان المحمي بالخصوصية، وإصدار شهادات الأصول الخالية من المعرفة، وما إلى ذلك، استنادًا إلى حل الحوسبة خارج السلسلة لإثبات المعرفة الصفرية.",
  t59: "أمن المدينة الذكية وإنترنت الأشياء",
  t60: "يحمي خصوصية بيانات الجهاز بفعالية عبر جميع المنافذ في سيناريوهات المدينة الذكية وإنترنت الأشياء مع تحسين توافر البيانات.",
  t61: "تطبيقات الهوية الرقمية",
  t62: "تعزيز قابلية استخدام الهويات الرقمية دون التضحية بخصوصية معلومات المستخدم، مما يوفر المزيد من الراحة للمستخدمين.",
  t63: "الإدارة المستدامة للموارد",
  t64: "يمكن للمؤسسات أو المنظمات تتبع الموارد الهامة مثل الطاقة وتخصيصها بفعالية وكفاءة، بينما يمكن التحقق من كل رابط من البيانات في السلسلة.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "محفظة الأعمال",
  "t102-1": "我們的業務组合",
  t103: "الصحة والبحوث الطبية",
  "t103-1":
    "يحمي نظام ENIAC خصوصية المرضى والمستخدمين، مما يسمح بإجراء أبحاث طبية فعالة دون أن يتمكن فريق البحث من الوصول إلى هذه المعلومات الخاصة.",
  t104: "حماية حقوق النشر وإنشاء المحتوى",
  "t104-1":
    "من خلال ENIAC، يمكن للسوق إنشاء آلية أكثر كفاءة لحماية حقوق النشر والترخيص، مما يحقق إعمال حقوق الملكية الفكرية وتداولها بشكل سليم.",
  t105: "سوق خاص تمامًا للتصويت والبحث عبر الإنترنت.",
  "t105-1":
    "استنادًا إلى حوسبة السلسلة السفلية لإثبات المعرفة الصفرية، يمكن للمستخدمين الحصول على نتائج تحليل أكثر تعمقًا دون الكشف عن بيانات البحث أو لمسها.",
};
