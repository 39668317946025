export default {
  home: "Home",
  about: "About",
  document: "Whitepaper",
  t1: "Introducing Decentralized General-purpose Computing",
  t2: "Enterprise General-Purpose Computing Blockchain",
  t3: "Ethereum's First Decentralized Network of Modular General-purpose Computing Coprocessor",
  t4: "Ethereum",
  t5: "De General-purpose Computing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "Computing Network For Enterprise-Level Apps",
  t10: "A Faster, stronger, decentralized computing network. ",
  t11: "Developers and businesses can freely deploy high-performance apps.",
  t12: "Even Faster",
  t13: "Up to 300ms block confirmation speed. TPS up to 20,000.",
  t14: "Low Costs",
  t15: "Decentralized large-scale off-chain computing solution effectively reduces the cost of computing power for enterprises.",
  t16: "Enterprise-Grade Privacy Security",
  t17: "Enterprise's privacy and sensitive data are protected by off-chain computing.",
  t18: "General-purpose Computing Capability",
  t19: "Based on more advanced scaling solutions, Ethereum can supply enterprise-level computing needs.",
  t20: "Decentralized and Transparent",
  t21: "The first decentralized Ethereum Layer2 that introduces a consensus layer.",
  t22: "Compatibility and Extensibility",
  t23: "Supports Type1 Ethereum compatibility while enabling all features and advanced capabilities of development languages based on zkVM.",
  t24: " Core Architecture",
  t25: "App Rollups",
  t26: "ZK Execution Layer",
  t27: "Distributed Computing Network",
  t28: "Consensus Layer",
  t29: "DPoR Mechanism",
  "t29-1": "Node Collab Adaptively",
  t30: "Verification Processing",
  t31: "Distributed Verification Network",
  "t31-1": "RPoW Mechanism",
  t32: "Settlement Layer",
  t33: "Ethereum Network",
  "t33-1": "",
  t34: "Core App Modules Based On",
  t35: "Architecture",
  t36: "In the future, ENIAC will be the first to provide comprehensive enterprise-level solutions in these fields.",
  t37: "Zero-knowledge Machine Learning ",
  t38: "Zero-knowledge proofing combined with machine learning to improve efficiency while protecting data privacy and trustworthiness.",
  t39: "Zero-knowledge Decentralized Metaverse",
  t40: "Provides a trusted environment and powerful low-cost computing infrastructure for the metaverse.",
  t41: "Zero-knowledge Decentralized Identity",
  t42: "Provides users with a trusted digital identity based on a decentralized network and zero-knowledge authentication.",
  t43: "Rollup As A Service ",
  t44: "Supports rapid deployment of enterprise rollup based on zkVM.",
  t45: "Explore ",
  t46: "Exploring the unlimited possibilities of decentralized general-purpose computing.",
  t47: "Standardized Enterprise-Grade Blockchain Application Solutions",
  t48: "Rapid deployment, on-demand for startups",
  t49: "Modularized and Rapidly Customized Enterprise Blockchain Products and Services",
  t50: "For SMEs, flexible options, fast adaptation",
  t51: "Deep Customization Based On App Rollups",
  t52: "For large and ultra-large enterprises, full range of in-depth customization",
  t53: "Enterprise Data Privacy & Security",
  t54: "ENIAC supports the complete retention of confidential data between companies while accomplishing data interactions and business cooperation data on the chain.",
  t55: "Supply Chain Management",
  t56: "With ENIAC, organizations can quickly build a trusted supply chain blockchain management system without revealing details, simply validate the results of critical link data on the chain.",
  t57: "Innovative Finance & Products",
  t58: "ENIAC supports customers to develop new financial services, such as privacy-protected credit assessment, zero-knowledge asset certification and etc., based on the off-chain computing solution of zero-knowledge proof.",
  t59: "Smart City & IoT Security",
  t60: "Effectively protects the privacy of device data across all ports in smart city and IoT scenarios while improving data availability.",
  t61: "Digital Identity Applications",
  t62: "Enhance the usability of digital identities without sacrificing the privacy of user information, bringing more convenience to users.",
  t63: "Sustainable Resource Management",
  t64: "Enterprises or organizations can effectively trace and efficiently allocate critical resources such as energy, while each link of data can be verified up the chain.",
  t65: "The first modular general-purpose computing coprocessor of Ethereum.",
  t66: "About Us",
  t67: "ENIAC is an enterprise-level blockchain, the first modular general-purpose computing coprocessor distributed network. Its vision is based on the comprehensive performance brought by modularity, aiming to create a three-tier business solution: standardized products, customized products, and deeply customized products, on the basis of traditional blockchain business. This aims to help Ethereum rapidly expand its enterprise-level user base, enhance Ethereum's practical value, and expand its user base.",
  t68: "ENIAC's social media and communities.",
  t69: "Computing Network For Enterprise-Level Apps",
  t70: "",
  t71: "",
  t72: "App Rollups",
  t73: "ZK Execution Layer",
  t74: "Distributed Computing Network",
  t75: "Consensus Layer",
  t76: "DPoR Mechanism",
  t77: "Node Collab Adaptively",
  t78: "Settlement Layer",
  t79: "Ethereum Network",
  t80: "Tomorrow's ENIAC",
  t81: "Our business portfolio",
  t82: "Health and medical research",
  t83: "ENIAC can effectively protect patient and user health privacy, allowing medical research teams to conduct effective medical research without accessing this private data.",
  t84: "Copyright and content creation protection",
  t85: "With ENIAC, the market can establish more efficient copyright protection and licensing mechanisms, realizing the monetization and circulation of intellectual property in a healthy manner.",
  t86: "Completely private online voting and survey market",
  t87: "Based on zero-knowledge proof chain computation, users can obtain more in-depth analysis results without the need to disclose or access survey data.",
  t88: "Privacy policy",
  t89: "Company introduction",
  t90: "ENIAC is an enterprise-level blockchain, the first modularized general-purpose computing coprocessor distributed network. Its vision is to create standardized, customized, and deeply customized product solutions for enterprises based on the comprehensive performance brought by modularization. It aims to assist Ethereum in rapidly expanding its enterprise-level users, creating practical value for Ethereum, and expanding its user base.",
  t91: "Contact us",
  t92: "ENIAC social media accounts and community",
  t93: "Frequently Asked Questions",
  t94: "Why Eniac? What is the inspiration?",
  t95: "It all started with 'Ethereum's world computer', an idea first proposed by Vitalik Buterin in 2014. He envisioned building a global computing infrastructure with distributed, publicly transparent, and always-online characteristics. Eniac embodies this ideal.",
  t96: "We believe that Ethereum or existing public chains should not be limited to simple smart contract platforms for specific tasks. As more enterprises and developers embrace blockchain, the market needs a platform that is more tolerant of complex scenarios. That's where Eniac comes in. As a new scaling solution, it introduces general computing capabilities to Ethereum and other Layer 1 solutions. From Web3 to Web2, Eniac allows anyone to quickly establish blockchain solutions, including direct migration of existing businesses. ENIAC is named in honor of the world's first general-purpose computer, the Electronic Numerical Integrator And Computer.",
  t97: "What are the advantages of Eniac?",
  t98: "Eniac is one of the few scaling solutions on the current market that combines decentralization and general computing capabilities. Benefiting from zk-Rollup and modularization, off-chain computation can handle more complex computing tasks. The theoretical block confirmation speed can reach 300ms, with a maximum TPS of 20,000. Therefore, Eniac can meet computing requirements across multiple scenarios, not just transactional smart contracts. Additionally, Eniac introduces a brand new DPoR decentralized consensus mechanism, ensuring network efficiency while maintaining platform transparency.",
  t99: "Furthermore, Eniac has high compatibility and openness. Eniac integrates two mainstream virtual machines: zkEVM and zkVM. zkEVM is compatible with all mainstream EVMs. Eniac zkVM adopts the RISC instruction set, supporting development in Rust, C/C++, Java, and other languages. Existing businesses can also be quickly migrated.",
  t100: "What market gap does Eniac fill?",
  t101: "Eniac fills the gap in the decentralization and blockchain fields for decentralized general computing. Currently, the Internet cannot find a suitable platform for building blockchain and decentralized applications. In fact, Eniac fills the gap between Web2 and Web3.",
  t102: "ENIAC ：",
  "t102-1": " Business",
  t103: "Health & Medical Research",
  "t103-1":
    "ENIAC protects the privacy of patients and users, allowing effective medical research to be conducted without the research team having access to this private information.",
  t104: "Copyright & Content Creation Protection",
  "t104-1":
    "With ENIAC, the market can establish a more efficient copyright protection and licensing mechanism, realizing healthy intellectual property rights realization and circulation.",
  t105: "Completely Private Online Voting & Research Marketplace",
  "t105-1":
    "Based on zero-knowledge proof off-chain computing, users can obtain more in-depth analysis results without disclosing or revealing the research data.",
  t106: "Sponsor",
};
