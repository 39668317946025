<script setup></script>
<template>
  <svg viewBox="0 0 157 162" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_153_511)">
      <path
        d="M76.663 72.4674V71.0978H80.087V72.4674C81.6848 72.9239 83.2826 73.6087 84.1957 74.9783L85.337 74.2935L87.163 77.2609L86.0217 77.9457C86.25 78.6304 86.4783 79.5435 86.4783 80.4565C86.4783 81.3696 86.25 82.0543 86.0217 82.9674L87.163 83.6522L85.337 86.6196L84.1957 85.9348C83.0543 87.0761 81.6848 87.9891 80.087 88.4457V89.3587H76.663V87.9891C75.0652 87.5326 73.4674 86.8478 72.5543 85.4783L71.413 86.163L69.587 83.1957L70.7283 82.5109C70.5 81.8261 70.2717 80.913 70.2717 80C70.2717 79.087 70.5 78.4022 70.7283 77.4891L69.587 76.8043L71.413 74.2935L72.5543 74.9783C73.6957 73.6087 75.0652 72.6957 76.663 72.4674ZM85.337 98.9456L80.087 93.6957C86.9348 92.7826 91.9565 87.0761 91.9565 80.2283C91.9565 73.6087 87.163 68.1304 81 66.7609L76.2065 61.9674H78.2609C88.3043 61.9674 96.5217 70.1848 96.5217 80.2283C96.5217 87.0761 92.8696 92.7826 87.3913 95.9783V101L85.337 98.9456ZM80.5435 98.2609C79.8587 98.2609 78.9457 98.4891 78.2609 98.4891C68.2174 98.4891 60 90.2717 60 80.2283C60 73.3804 63.6522 67.6739 69.1304 64.4783V59L72.3261 62.1957L76.663 66.5326C69.8152 67.4457 64.5652 73.1522 64.5652 80.2283C64.5652 87.0761 69.587 92.5543 75.9783 93.6957L80.5435 98.2609ZM78.4891 84.7935C81 84.7935 83.0543 82.7391 83.0543 80.2283C83.0543 77.7174 81 75.663 78.4891 75.663C75.9783 75.663 73.9239 77.7174 73.9239 80.2283C73.9239 82.7391 75.9783 84.7935 78.4891 84.7935ZM78.4891 82.5109C77.1196 82.5109 76.2065 81.5978 76.2065 80.2283C76.2065 78.8587 77.1196 77.9457 78.4891 77.9457C79.8587 77.9457 80.7717 78.8587 80.7717 80.2283C80.7717 81.5978 79.6304 82.5109 78.4891 82.5109Z"
        fill="#55FF99"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_511"
        x="0"
        y="0"
        width="156.521"
        height="162"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="30" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0.678431 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_511"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_511"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 14px;
  height: 16px;
  transform: scale(8.5);
}
</style>
