<script setup></script>
<template>
  <svg viewBox="0 0 79 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Union" filter="url(#filter0_d_153_172)">
      <path
        d="M58.0007 51.2322C56.838 48.4896 54.0322 46.9461 51.2159 47.2621L46.2612 35.575C49.3941 33.3189 50.6676 29.1218 49.0971 25.4173C48.7627 24.6287 48.3227 23.9172 47.8005 23.2923L55.6368 12.9028C57.0304 13.2999 58.5638 13.2412 60.0018 12.6316C63.3454 11.2141 64.9068 7.3544 63.4893 4.01078C62.0717 0.667165 58.2121 -0.894233 54.8685 0.523303C51.5248 1.94084 49.9634 5.80052 51.381 9.14413C51.6555 9.79165 52.0216 10.3723 52.4578 10.8775L44.8085 21.0191C42.7305 20.0763 40.2796 19.9725 38.0132 20.9334C35.6177 21.949 33.9337 23.9395 33.2274 26.2469L19.407 24.5437C19.3285 23.9887 19.1772 23.4347 18.948 22.8942C17.5305 19.5506 13.6708 17.9892 10.3272 19.4067C6.98357 20.8242 5.42217 24.6839 6.83971 28.0275C8.25725 31.3711 12.1169 32.9325 15.4605 31.515C17.0118 30.8573 18.1795 29.674 18.844 28.2603L32.9543 29.9992C33.059 30.6784 33.2488 31.3557 33.5293 32.0174C35.0998 35.7218 39.0018 37.7247 42.8017 37.0417L47.7564 48.7287C45.5711 50.5332 44.7297 53.623 45.8924 56.3655C47.3099 59.7091 51.1696 61.2705 54.5132 59.853C57.8568 58.4354 59.4182 54.5758 58.0007 51.2322Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_172"
        x="0.316406"
        y="0"
        width="77.6953"
        height="80.3763"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="10" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00541523 0 0 0 0 0.0342157 0 0 0 0 0.0433105 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_172"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_172"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 23px;
  height: 24px;
}
</style>
