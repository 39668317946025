export default {
  home: "Casa",
  about: "Su",
  document: "Documento",
  t1: "引入去中心化通用計算的",
  t2: "Blockchain per l'elaborazione generica d'impresa",
  t3: "La prima rete decentralizzata di coprocessori modulari di General Purpose Computing di Ethernet",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "Soddisfare le esigenze di calcolo delle applicazioni di classe enterprise",
  t10: "Una rete di calcolo generale decentralizzata più veloce, più forte e più sicura",
  t11: "Gli sviluppatori e le aziende sono liberi di creare e distribuire applicazioni ad alte prestazioni.",
  t12: "Più veloce del veloce",
  t13: "Velocità di convalida dei blocchi fino a 300 ms, TPS fino a 20.000",
  t14: "Costi di calcolo ridotti",
  t15: "La soluzione di off-chain computing decentralizzata su larga scala riduce efficacemente il costo della potenza di calcolo per le aziende.",
  t16: "Sicurezza della privacy di livello aziendale",
  t17: "La privacy aziendale e i calcoli sensibili sono protetti dall'off-chain computing.",
  t18: "Capacità di elaborazione generale",
  t19: "Con soluzioni di scaling più avanzate, Ethernet può soddisfare anche le esigenze di calcolo delle applicazioni aziendali.",
  t20: "Decentrata e trasparente",
  t21: "La prima rete decentralizzata Layer2 in Ethernet a introdurre un livello di consenso",
  t22: "Compatibilità ed estensibilità",
  t23: "Supporta la compatibilità con Ethernet di tipo 1, nonché tutte le funzionalità e le caratteristiche avanzate del linguaggio di sviluppo basato su zkVM.",
  t24: "Architettura di base di ENIAC",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "Moduli applicativi fondamentali basati sull'architettura di ENIAC",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "Apprendimento automatico a conoscenza zero ",
  t38: "La prova della conoscenza zero combinata con l'apprendimento automatico per migliorare l'efficienza proteggendo la privacy e l'affidabilità dei dati.",
  t39: "Metaverso decentralizzato a conoscenza zero",
  t40: "Fornisce un ambiente affidabile e una potente infrastruttura informatica a basso costo per il metaverso.",
  t41: "Identità decentralizzata a conoscenza zero",
  t42: "Fornisce agli utenti un'identità digitale affidabile basata su una rete decentralizzata e sull'autenticazione a conoscenza zero.",
  t43: "Rollup come servizio ",
  t44: "Supporta la distribuzione rapida di Rollup aziendali basati su zkVM.",
  t45: "Esplora ENIAC",
  t46: "Esplorare le illimitate possibilità del General Purpose Computing decentralizzato",
  t47: "l Soluzioni applicative blockchain standardizzate di livello aziendale",
  t48: "Distribuzione rapida, on-demand per le startup",
  t49: "l Prodotti e servizi blockchain aziendali modulari e rapidamente personalizzati",
  t50: "Per le PMI, opzioni flessibili, adattamento rapido",
  t51: "l Personalizzazione profonda basata su AppRollups",
  t52: "Per le grandi e grandissime imprese, gamma completa di personalizzazioni approfondite",
  t53: "Collaborazione con i dati aziendali Privacy e sicurezza",
  t54: "ENIAC supporta la conservazione completa dei dati confidenziali tra le aziende, realizzando al contempo interazioni di dati e cooperazione commerciale sulla catena.",
  t55: "Gestione della catena di fornitura",
  t56: "Con ENIAC, le organizzazioni possono costruire rapidamente un sistema di gestione della supply chain blockchain affidabile senza rivelare i dettagli, semplicemente convalidando i risultati dei dati di collegamento critici sulla catena.",
  t57: "Finanza e prodotti innovativi",
  t58: "ENIAC supporta i clienti nello sviluppo di nuovi servizi finanziari, come la valutazione del credito protetta dalla privacy, la certificazione di beni a conoscenza zero, ecc.",
  t59: "Sicurezza delle Smart City e dell'IoT",
  t60: "Protegge efficacemente la privacy dei dati dei dispositivi su tutte le porte negli scenari smart city e IoT, migliorando al contempo la disponibilità dei dati.",
  t61: "Applicazioni di identità digitale",
  t62: "Migliora l'usabilità delle identità digitali senza sacrificare la privacy delle informazioni dell'utente, offrendo maggiore comodità agli utenti.",
  t63: "Gestione sostenibile delle risorse",
  t64: "Le imprese o le organizzazioni possono tracciare e allocare in modo efficace risorse critiche come l'energia, mentre ogni anello di dati può essere verificato lungo la catena.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "Portafoglio aziendale",
  "t102-1": "我們的業務组合",
  t103: "Salute e ricerca medica",
  "t103-1":
    "ENIAC protegge la privacy di pazienti e utenti, consentendo di condurre ricerche mediche efficaci senza che il team di ricerca abbia accesso a queste informazioni private.",
  t104: "Protezione del copyright e della creazione di contenuti",
  "t104-1":
    "Con ENIAC, il mercato può stabilire un meccanismo più efficiente di protezione del copyright e di concessione di licenze, realizzando una sana realizzazione e circolazione dei diritti di proprietà intellettuale.",
  t105: "Mercato di ricerca e votazione online completamente privato.",
  "t105-1":
    "Basandosi sul calcolo a catena discendente a prova di conoscenza zero, gli utenti possono ottenere risultati di analisi più approfonditi senza rivelare o toccare i dati di ricerca.",
};
