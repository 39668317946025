<script setup></script>
<template>
  <div class="SvgView31"></div>
</template>
<style lang="less" scoped>
.SvgView31 {
  width: 65px;
  height: 65px;
  background: url("@/assets/bg/9.png") 0 0 / 100% no-repeat;
}
</style>
