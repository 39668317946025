<script setup></script>
<template>
  <svg viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 621">
      <path
        id="Vector"
        d="M97.9946 104.608L72.5601 72.7182L13.5176 79.4639L97.9875 104.614L97.9946 104.608Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M91.9 16.9474L72.5598 72.7175L97.9943 104.607L91.9 16.9474Z"
        fill="url(#paint0_linear_153_402)"
      />
      <path
        id="Vector 9"
        d="M39.5312 31.6842L91.1199 17.6778L72.1923 72.6348L14.4022 78.8657L39.5312 31.6842Z"
        stroke="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_402"
        x1="67.1179"
        y1="36.7128"
        x2="114.692"
        y2="91.4888"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565B63" />
        <stop offset="1" stop-color="#616161" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 37px;
  height: 37px;
}
</style>
