<script setup>
import { ref, reactive, onUnmounted, defineExpose } from "vue";

// 获取当前盒子的dom
const boxRef = ref(null);

const getRandom = (type) => {
  const n = Math.random() + 0.1;
  return (type ? n : -n) - 0.2;
};

// 运动方向参数
const toInfo = reactive({
  x: getRandom(Math.random() > 0.5),
  y: getRandom(Math.random() > 0.5),
  left: 0,
  top: 0,
});

// 运动方法
const draw = () => {
  const box = boxRef.value;
  toInfo.left += toInfo.x;
  toInfo.top += toInfo.y;
  box.style.top = toInfo.top + "px";
  box.style.left = toInfo.left + "px";
};

const t = ref(null);

// 初始化方法
const init = (event, type) => {
  // 随机选个位置
  const { width: W, height: H } = event;
  const box = boxRef.value;
  if (type) {
    cancelAnimationFrame(t.value);
  } else {
    toInfo.left = Math.random() * W;
    toInfo.top += Math.random() * H;
    box.style.top = toInfo.top + "px";
    box.style.left = toInfo.left + "px";
  }

  const fn = () => {
    // 获取盒子大小
    const { width, height } = boxRef.value.getBoundingClientRect();

    // 获取父级盒子大小
    const { width: W, height: H } = event;
    // 检测当前是否到了边界
    const x = boxRef.value.offsetLeft;
    const y = boxRef.value.offsetTop;
    if (width + x >= W - toInfo.x) {
      // 右边边界
      toInfo.x = getRandom(0);
    }
    if (x <= 0) {
      // 左边边界
      toInfo.x = getRandom(1);
    }
    if (height + y >= H - toInfo.y) {
      // 下边边界
      toInfo.y = getRandom(0);
    }
    if (y <= 0) {
      // 上边边界
      toInfo.y = getRandom(1);
    }
    draw();
    t.value = requestAnimationFrame(fn);
  };
  fn();
};

onUnmounted(() => {
  cancelAnimationFrame(t.value);
});

defineExpose({
  init,
});
</script>
<template>
  <div class="AnimationBox_root" ref="boxRef">
    <slot></slot>
  </div>
</template>
<style scoped lang="less">
.AnimationBox_root {
  position: absolute;
}
</style>
