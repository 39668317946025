<script setup></script>
<template>
  <div class="ContentViewN1_root">
    <div>
      <div>{{ $t("t18") }}</div>
      <div>
        {{ $t("t19") }}
      </div>
    </div>
    <div>
      <div>{{ $t("t12") }}</div>
      <div>
        {{ $t("t13") }}
      </div>
    </div>
    <div>
      <div>{{ $t("t20") }}</div>
      <div>
        {{ $t("t21") }}
      </div>
    </div>
    <div>
      <div>{{ $t("t22") }}</div>
      <div>{{ $t("t23") }}</div>
    </div>
    <div>
      <div>{{ $t("t16") }}</div>
      <div>
        {{ $t("t17") }}
      </div>
    </div>
    <div>
      <div>{{ $t("t14") }}</div>
      <div>
        {{ $t("t15") }}
      </div>
    </div>
    <div class="ContentViewN1_root_last"></div>
  </div>
</template>
<style scoped lang="less">
.ContentViewN1_root {
  height: 263px;
  background: url("@/assets/bg/1.png") 0 0 / 100% 100% no-repeat;
  position: relative;
  margin-top: 40px;
  & > div {
    position: absolute;
    color: #fff;
    font-size: 12px;
    width: 130px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 10px;
    transition: all 0.8s;
    cursor: pointer;
    z-index: 2;
    &.active,
    &:hover {
      border-color: #61ff71;
    }
    & > div:nth-child(1) {
      font-size: 12px;
    }
    & > div:nth-child(2) {
      font-size: 6px;
      margin-top: 6px;
    }
  }
  & > div:nth-child(1) {
    left: 105px;
    top: 20px;
  }
  & > div:nth-child(2) {
    left: 256px;
    top: 15px;
  }
  & > div:nth-child(3) {
    left: 492px;
    top: 0px;
  }
  & > div:nth-child(4) {
    left: 146px;
    top: 150px;
  }
  & > div:nth-child(5) {
    left: 307px;
    top: 110px;
  }
  & > div:nth-child(6) {
    left: 510px;
    top: 130px;
  }
  .ContentViewN1_root_last {
    width: 650px;
    height: 84px;
    border: none;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -325px;
    z-index: 1;
    background: url("@/assets/bg/2.png") 0 0 / 100% 100% no-repeat;
  }
}
</style>
