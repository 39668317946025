<script setup></script>
<template>
  <svg viewBox="0 0 200 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M2.24827 0H35.2462C35.858 0 36.3851 0.213769 36.831 0.641308C37.2733 1.06885 37.4982 1.58853 37.4982 2.20035C37.4982 2.81218 37.277 3.33923 36.831 3.7852C36.3888 4.22748 35.858 4.45231 35.2462 4.45231H4.4523V23.5442H35.2462C35.858 23.5442 36.3851 23.7653 36.831 24.2113C37.2733 24.6535 37.4982 25.1843 37.4982 25.7961C37.4982 26.4079 37.277 26.935 36.831 27.3809C36.3888 27.8232 35.858 28.0481 35.2462 28.0481H2.24827C1.60696 28.0481 1.06885 27.8269 0.641306 27.3809C0.213766 26.9387 0 26.4079 0 25.7961V2.20404C0 1.59221 0.213766 1.07253 0.641306 0.644995C1.06885 0.217455 1.60328 0.00368366 2.24827 0.00368366V0ZM11.5657 11.75H32.9058C33.5176 11.75 34.0373 11.9711 34.4648 12.4171C34.8924 12.8594 35.1061 13.3901 35.1061 14.0019C35.1061 14.6137 34.8924 15.1408 34.4648 15.5868C34.0373 16.029 33.5176 16.2539 32.9058 16.2539H11.5657C10.9244 16.2539 10.3863 16.0327 9.95872 15.5868C9.53118 15.1445 9.31741 14.6174 9.31741 14.0019C9.31741 13.3864 9.53118 12.863 9.95872 12.4171C10.3863 11.9748 10.9207 11.75 11.5657 11.75Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M48.829 0H48.8733C49.3008 0 49.6989 0.121628 50.0674 0.368568L82.0076 21.6165V2.20404C82.0076 1.59221 82.2213 1.07253 82.6489 0.644995C83.0764 0.217455 83.6035 0.00368366 84.2337 0.00368366C84.864 0.00368366 85.3874 0.217455 85.8186 0.644995C86.2461 1.07253 86.4599 1.59221 86.4599 2.20404V25.7924C86.4599 26.4042 86.2461 26.9313 85.8186 27.3773C85.391 27.8195 84.8566 28.0444 84.2116 28.0444C83.7841 28.0444 83.3713 27.908 82.9732 27.6316L51.0773 6.38361V25.7961C51.0773 26.4079 50.8635 26.935 50.436 27.3809C50.0085 27.8232 49.4814 28.0481 48.8512 28.0481C48.2209 28.0481 47.6975 27.8269 47.2663 27.3809C46.8388 26.9387 46.625 26.4079 46.625 25.7961V2.20404C46.625 1.59221 46.8388 1.07253 47.2663 0.644995C47.6938 0.217455 48.2135 0.00368366 48.8254 0.00368366L48.829 0Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M96.237 0.644729C96.6645 0.21719 97.1916 0.00341797 97.8218 0.00341797C98.4521 0.00341797 98.9755 0.21719 99.4067 0.644729C99.8343 1.07227 100.048 1.59195 100.048 2.20377V25.7922C100.048 26.404 99.8343 26.931 99.4067 27.377C98.9792 27.8193 98.4484 28.0441 97.8218 28.0441C97.1953 28.0441 96.6682 27.823 96.237 27.377C95.8095 26.9347 95.5957 26.404 95.5957 25.7922V2.20377C95.5957 1.59195 95.8095 1.07227 96.237 0.644729Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M131.572 0C132.246 0 132.781 0.261682 133.179 0.781363L153.233 24.3697C153.601 24.7678 153.782 25.2433 153.782 25.7924C153.782 26.4632 153.52 27.0308 153.001 27.4915C152.573 27.8601 152.083 28.0407 151.53 28.0407C150.855 28.0407 150.292 27.779 149.831 27.2593L131.472 5.64278L113.162 27.2593C112.705 27.779 112.137 28.0407 111.463 28.0407C110.914 28.0407 110.424 27.8564 109.992 27.4915C109.473 27.0345 109.211 26.4669 109.211 25.7924C109.211 25.2433 109.395 24.7678 109.76 24.3697L129.77 0.781363C130.23 0.261682 130.794 0 131.469 0H131.561H131.572Z"
        fill="white"
      />
      <path
        id="Vector_5"
        d="M174.208 0H197.753C198.394 0 198.932 0.213769 199.36 0.641308C199.787 1.06885 200.001 1.58853 200.001 2.20035C200.001 2.81218 199.787 3.33923 199.36 3.7852C198.932 4.22748 198.394 4.45231 197.753 4.45231H174.208C171.547 4.45231 169.281 5.37741 167.416 7.22763C165.551 9.07784 164.618 11.3335 164.618 13.9982C164.618 16.663 165.551 18.9149 167.416 20.7688C169.281 22.619 171.547 23.5442 174.208 23.5442H197.753C198.394 23.5442 198.932 23.7653 199.36 24.2113C199.787 24.6535 200.001 25.1843 200.001 25.7961C200.001 26.4079 199.787 26.935 199.36 27.3809C198.932 27.8232 198.394 28.0481 197.753 28.0481H174.208C170.324 28.0481 167.01 26.6696 164.272 23.9164C161.533 21.1632 160.166 17.8571 160.166 14.0019C160.166 10.1467 161.533 6.85169 164.272 4.11322C167.01 1.37476 170.32 0.00737013 174.208 0.00737013V0Z"
        fill="white"
      />
    </g>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 78px;
  height: 11px;
}
</style>
