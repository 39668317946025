import { createStore } from "vuex";

export const language_key = "eniac_localStorage_language_key";

// 本地存储没有就取浏览器默认
const getLocalStorage = () => {
  let local = localStorage.getItem(language_key);
  if (!local) {
    local = navigator.language?.slice(0, 2);
  }
  return local;
};

console.log(getLocalStorage());

export default createStore({
  state: {
    language: getLocalStorage() ?? "en",
  },
  getters: {
    getLanguage(state) {
      return state.language;
    },
  },
  mutations: {
    setLanguage(state, lang) {
      // 给缓存中添加数据
      localStorage.setItem(language_key, lang);
      state.language = lang;
    },
  },
  actions: {},
  modules: {},
});
