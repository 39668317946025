<script setup></script>
<template>
  <svg viewBox="0 0 155 164" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 622">
      <g id="Group 620">
        <path
          id="Vector"
          d="M69.9392 155.098L141.377 125.612L86.0176 9.3663L69.9392 155.098Z"
          stroke="white"
        />
        <path
          id="Vector_2"
          d="M5.65136 110.638L68.9428 154.989L85.0212 9.25671L5.65136 110.638Z"
          stroke="white"
        />
      </g>
    </g>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 49px;
  height: 53px;
}
</style>
