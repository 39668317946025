<script setup>
import SvgView19 from "@/views/home/svg/SvgView19.vue";
import t3 from "@/assets/bg/letter.svg";
import t4 from "@/assets/bg/telegram.svg";
import t5 from "@/assets/bg/discord.svg";
import t6 from "@/assets/bg/medium.svg";
import t7 from "@/assets/bg/x.svg";
import t8 from "@/assets/bg/reddit.svg";
import t9 from "@/assets/bg/telegram-2.svg";
import logo_footer from "@/views/home/mobileSvg/Group.svg";
import { ref } from "vue";

const index = ref(1);

const total = 3;

const showIndex = ref(-1);

const addIndex = () => {
  if (index.value === total) {
    index.value = 1;
  } else {
    index.value = ++index.value;
  }
};

const cIndex = () => {
  if (index.value === 1) {
    index.value = total;
  } else {
    index.value = --index.value;
  }
};
</script>
<template>
  <div class="mobileView_root">
    <div class="mobileView_root_n4">
      <div class="mobileView_root_n4_logo">
        <img src="../mobileSvg/1.svg" alt="" />
        <img src="../mobileSvg/4.svg" alt="" />
        <img src="../mobileSvg/3.svg" alt="" />
        <img src="../mobileSvg/2.svg" alt="" />
      </div>
    </div>
    <!-- <div class="mobileView_root_n1 t-center">ENIAC</div> -->
    <div class="mobileView_root_n2 t-center">
      {{ $t("t2") }}
    </div>
    <div class="mobileView_root_n3 t-center">
      {{ $t("t3") }}
    </div>
    <div class="mobileView_root_n3_1 d-flex f-j-center">
      <div class="d-flex f-a-center">
        <img src="../mobileSvg/30-30-8.svg" alt="" />
        <span>De ZK_Rollup</span>
      </div>

      <div class="d-flex f-a-center">
        <img src="../mobileSvg/30-30-10.svg" alt="" />
        <span>300ms</span>
      </div>
      <div class="d-flex f-a-center">
        <img src="../mobileSvg/30-30-9.svg" alt="" />
        <span>20,000</span>
      </div>
    </div>

    <div class="mobileView_root_n3_2 t-center">
      {{ $t("t69") }}<span>{{ $t("t70") }}</span
      >{{ $t("t71") }}
      <div class="mobileView_root_n3_2_1 t-center">
        {{ $t("t10") }}{{ $t("t11") }}
      </div>
    </div>

    <div class="mobileView_root_n3_3">
      <div class="mobileView_root_n3_3_1 d-flex f-j-center">
        <div>
          <span>{{ $t("t18") }}</span>
          <div>{{ $t("t19") }}</div>
        </div>
        <div>
          <span>{{ $t("t22") }}</span>
          <div>
            {{ $t("t23") }}
          </div>
        </div>
        <div>
          <span>{{ $t("t20") }}</span>
          <div>{{ $t("t21") }}</div>
        </div>
        <div>
          <span>{{ $t("t16") }}</span>
          <div>{{ $t("t17") }}</div>
        </div>
        <div>
          <span>{{ $t("t12") }}</span>
          <div>{{ $t("t13") }}</div>
        </div>
        <div>
          <span>{{ $t("t14") }}</span>
          <div>{{ $t("t15") }}</div>
        </div>
      </div>
    </div>

    <div class="mobileView_root_n8 t-center">
      <span>ENIAC</span>
      <span>{{ $t("t24") }}</span>
    </div>
    <div class="mobileView_root_n3_4">
      <div class="mobileView_root_n3_4_item d-flex f-a-center f-j-sb">
        <span class="rotate270">{{ $t("t72") }}</span>
        <div
          class="mobileView_root_n3_4_item_list mobileView_root_n3_4_item_list_2 d-flex"
        >
          <span>ZKML</span>
          <span>ZKDMV</span>
          <span>ZKDID</span>
          <span>RaaS</span>
          <span>...</span>
        </div>
      </div>
      <div class="jiantou_1">
        <img src="../mobileSvg/jiantou.svg" alt="" />
      </div>
      <div class="d-flex overflowAuto">
        <div>
          <div class="mobileView_root_n3_4_item d-flex f-a-center">
            <span class="rotate270" style="max-width: 100%">{{
              $t("t73")
            }}</span>
            <div
              class="mobileView_root_n3_4_item_n1 d-flex f-a-center"
              style="height: unset"
            >
              <span class="no-bg">{{ $t("t74") }}</span>
              <span>zkEVM based-Rollup</span>
              <span>zkVM based-Rollup</span>
            </div>
          </div>
          <div class="jiantou">
            <img src="../mobileSvg/jiantou.svg" alt="" />
          </div>
          <div class="mobileView_root_n3_4_item d-flex f-a-center">
            <span class="rotate270" style="max-width: 100%">{{
              $t("t75")
            }}</span>
            <div class="mobileView_root_n3_4_item_list d-flex">
              <span>{{ $t("t76") }}</span>
              <span>{{ $t("t77") }}</span>
              <span class="color-green no-bg">{{ $t("t30") }}</span>
            </div>
          </div>
          <div class="jiantou">
            <img src="../mobileSvg/jiantou.svg" alt="" />
          </div>
          <div class="mobileView_root_n3_4_item d-flex f-a-center">
            <span class="rotate270" style="max-width: 100%">{{
              $t("t78")
            }}</span>
            <div class="mobileView_root_n3_4_item3 d-flex f-a-center">
              <img src="../mobileSvg/30-50-12.png" alt="" />
              <span>{{ $t("t79") }}</span>
            </div>
          </div>
        </div>
        <div class="jiantou_3 d-flex f-j-center">
          <img src="../mobileSvg/jiantou.svg" alt="" />
          <img src="../mobileSvg/jiantou.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_4_item2 d-flex f-a-center f-j-center">
          <span class="t-center rotate270">
            {{ $t("t31") }}
          </span>
          <div class="t-center">
            <div class="rotate270">{{ $t("t31-1") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg1">
      <div class="mobileView_root_n10 t-center">
        {{ $t("t34") }}
        <span>ENIAC</span>
        {{ $t("t35") }}
      </div>
      <div class="mobileView_root_n11 t-center">
        {{ $t("t36") }}
      </div>
    </div>
    <div class="mobileView_root_n3_5 d-flex f-j-center">
      <div>
        <img src="../mobileSvg/7.svg" alt="" />
        <div class="t1">ZKML</div>
        <div class="mobileView_root_n12_n2">
          <div class="t2">{{ $t("t37") }}</div>
          <div class="t3">{{ $t("t38") }}</div>
        </div>
      </div>
      <div>
        <img src="../mobileSvg/5.svg" alt="" />
        <div class="t1">ZKDMV</div>
        <div class="mobileView_root_n12_n2">
          <div class="t2">{{ $t("t39") }}</div>
          <div class="t3">{{ $t("t40") }}</div>
        </div>
      </div>
      <div>
        <img src="../mobileSvg/6.svg" alt="" />
        <div class="t1">ZKDID</div>
        <div class="mobileView_root_n12_n2">
          <div class="t2">{{ $t("t41") }}</div>
          <div class="t3">{{ $t("t42") }}</div>
        </div>
      </div>
      <div>
        <img src="../mobileSvg/8.svg" alt="" />
        <div class="t1">RaaS</div>

        <div class="mobileView_root_n12_n2">
          <div class="t2">{{ $t("t43") }}</div>
          <div class="t3">{{ $t("t44") }}</div>
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="mobileView_root_n13 d-flex f-j-center">
        <SvgView19></SvgView19>
      </div>
      <div class="mobileView_root_n14 t-center">
        {{ $t("t45") }} <span>ENIAC</span>
      </div>
      <div class="mobileView_root_n15 t-center">
        {{ $t("t46") }}
      </div>
      <div class="mobileView_root_n14_1 t-center">
        {{ $t("t102") }}
        <span> {{ $t("t102-1") }}</span>
      </div>
    </div>

    <div class="mobileView_root_n3_6 d-flex f-a-center f-j-center">
      <img @click="cIndex" src="../mobileSvg/left-1.svg" alt="" />
      <div>
        <div v-show="index === 1">
          <img src="../mobileSvg/65-75-16.svg" alt="" />
          <div class="t-center t1">{{ $t("t47") }}</div>
          <div class="t-center t2">{{ $t("t48") }}</div>
        </div>
        <div v-show="index === 2">
          <img src="../mobileSvg/65-75-15.svg" alt="" />
          <div class="t-center t1">{{ $t("t49") }}</div>
          <div class="t-center t2">{{ $t("t50") }}</div>
        </div>
        <div v-show="index === 3">
          <img src="../mobileSvg/65-75-14.svg" alt="" />
          <div class="t-center t1">{{ $t("t51") }}</div>
          <div class="t-center t2">{{ $t("t52") }}</div>
        </div>
      </div>
      <img @click="addIndex" src="../mobileSvg/left-1.svg" alt="" />
    </div>
    <div class="mobileView_root_n3_7">
      <div :class="{ show_n2: showIndex === 0 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span>{{ $t("t53") }}</span>
          <img @click="showIndex = 0" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b1"></span>{{ $t("t53") }}</div>
            <div>
              {{ $t("t54") }}
            </div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 1 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span> {{ $t("t55") }}</span>
          <img @click="showIndex = 1" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b2"></span>{{ $t("t55") }}</div>
            <div>
              {{ $t("t56") }}
            </div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 2 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span> {{ $t("t57") }}</span>
          <img @click="showIndex = 2" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b3"></span>{{ $t("t57") }}</div>
            <div>
              {{ $t("t58") }}
            </div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 3 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span> {{ $t("t59") }}</span>
          <img @click="showIndex = 3" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b4"></span>{{ $t("t59") }}</div>
            <div>{{ $t("t60") }}</div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 4 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span> {{ $t("t61") }}</span>
          <img @click="showIndex = 4" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b5"></span>{{ $t("t61") }}</div>
            <div>{{ $t("t62") }}</div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 5 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span> {{ $t("t63") }}</span>
          <img @click="showIndex = 5" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b6"></span>{{ $t("t63") }}</div>
            <div>{{ $t("t64") }}</div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 6 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span>{{ $t("t82") }}</span>
          <img @click="showIndex = 6" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b7"></span>{{ $t("t82") }}</div>
            <div>{{ $t("t83") }}</div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 7 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span>{{ $t("t84") }}</span>
          <img @click="showIndex = 7" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b8"></span>{{ $t("t84") }}</div>
            <div>{{ $t("t85") }}</div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div :class="{ show_n2: showIndex === 8 }">
        <div class="mobileView_root_n3_7_n1 d-flex f-j-sb f-a-center">
          <span>{{ $t("t86") }}</span>
          <img @click="showIndex = 8" src="../mobileSvg/left-2.svg" alt="" />
        </div>
        <div class="mobileView_root_n3_7_n2 d-flex">
          <div>
            <div><span class="b9"></span>{{ $t("t86") }}</div>
            <div>
              {{ $t("t87") }}
            </div>
          </div>
          <img @click="showIndex = -1" src="../mobileSvg/left-1.svg" alt="" />
        </div>
      </div>
      <div class="mobileView_root_n16 t-center">
        {{ $t("t106") }}
        <div>
          <img src="@/assets/bg/sponsor-mobile.gif" alt="" />
        </div>
      </div>
    </div>
    <div class="footer_root_mobile t-center">
      <div class="footer_root_mobile_n1">
        <img :src="logo_footer" alt="" />
      </div>
      <div class="footer_root_mobile_n2">
        {{ $t("t65") }}
      </div>
      <div class="footer_root_mobile_n3 d-flex f-j-center">
        <a href="https://t.me/eniac_community" target="_blank">
          <img :src="t4" title="Telegram Group" />
        </a>
        <a href="https://t.me/eniac_official" target="_blank">
          <img :src="t9" title="Telegram Channel" />
        </a>
        <a href="https://discord.gg/UfkCsgXzNs" target="_blank">
          <img :src="t5" title="Discord" />
        </a>
        <a href="https://medium.com/@eniac_network" target="_blank">
          <img :src="t6" title="Medium" />
        </a>
        <a href="https://twitter.com/eniac_network" target="_blank">
          <img :src="t7" title="X Platform" />
        </a>
        <a href="https://www.reddit.com/r/ENIAC/" target="_blank">
          <img :src="t8" title="Reddit" />
        </a>
      </div>
      <div class="footer_root_mobile_n4 d-flex f-j-center f-a-center">
        <!-- <span> {{ $t("t88") }}</span> -->
        <div class="d-flex f-a-center f-j-center">
          <img :src="t3" title="Email Us" class="email-icon" />
          <span>contact@eniac.network</span>
        </div>
      </div>
      <div class="footer_root_mobile_n5">
        <span>&copy; 2023-{{ yearNow }} ENIAC Labs. All rights reserved</span>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less">
.bg1 {
  background: url("../mobileSvg/bg1.svg") 30% 30% / 100% no-repeat;
}
.bg2 {
  background: url("../mobileSvg/bg2.svg") 30% 20% / 200% no-repeat;
}
.overflowAuto {
  overflow: auto;
}
.color-green {
  color: #55ff99;
}
.no-bg {
  background-color: unset !important;
}
.rotate270 {
  transform: rotate(270deg);
}
.mobileView_root {
  color: #fff;
  box-sizing: border-box;
  --t-fontSize: 60px;
  --d-fontSize: 38px;
  --t-fontSize2: 50px;
  --d-fontSize2: 32px;
  --t-fontSize3: 44px;
  --d-fontSize3: 28px;
  & > div {
    margin: 0 22px;
  }
  .mobileView_root_n1 {
    font-size: var(--t-fontSize);
    color: rgba(66, 255, 130, 1);
  }
  .mobileView_root_n2 {
    font-size: var(--t-fontSize);
    padding-bottom: 20px;
  }

  .mobileView_root_n3 {
    padding-top: 20px;
    font-size: var(--d-fontSize);
  }
  .mobileView_root_n3_1 {
    font-size: var(--d-fontSize2);
    gap: 40px;
    padding: 70px 0;
    & > div {
      gap: 10px;
      & > img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .mobileView_root_n3_2 {
    font-size: var(--t-fontSize2);
    margin-top: 40px;
    & > span {
      color: rgba(85, 255, 153, 1);
    }
    .mobileView_root_n3_2_1 {
      font-size: var(--d-fontSize2);
      width: 90%;
      margin: 30px auto;
    }
  }
  .mobileView_root_n3_3 {
    overflow-x: auto;
    .mobileView_root_n3_3_1 {
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 40px;
      & > div {
        display: grid;
        align-items: baseline;
        width: 340px;
        height: 520px;
        overflow-y: auto;
        border: 2px solid rgba(85, 255, 153, 0.37);
        box-sizing: border-box;
        padding: 20px;
        color: #fff;
        background: linear-gradient(to right, #171717 0%, #42ff810f 100%);
        & > span {
          font-size: var(--t-fontSize3);
        }
        & > div {
          align-self: baseline;
          padding-top: 30px;
          font-size: var(--d-fontSize3);
          color: rgba(255, 255, 255, 0.5);
        }
      }
      & > div:nth-child(1) {
        left: 180px;
        top: 200px;
      }
      & > div:nth-child(2) {
        left: 630px;
        top: 70px;
      }
      & > div:nth-child(3) {
        right: 380px;
        top: 70px;
      }
      & > div:nth-child(4) {
        left: 260px;
        top: 480px;
      }
      & > div:nth-child(5) {
        left: 750px;
        top: 380px;
      }
      & > div:nth-child(6) {
        right: 280px;
        top: 430px;
      }
    }
  }
  .mobileView_root_n3_4 {
    margin: unset;
    box-sizing: border-box;
    width: 100vw;
    padding: 0 20px;
    .mobileView_root_n3_4_item {
      height: 280px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      & > span {
        max-width: 110px;
        font-size: 32px;
        color: rgba(72, 72, 72, 1);
        padding-left: 10px;
      }

      .mobileView_root_n3_4_item_list {
        color: #fff;
        font-size: 30px;
        gap: 10px;
        margin-right: 10px;
        align-items: center;
        flex-wrap: wrap;
        & > span {
          width: 100%;
          padding: 10px;
          background-color: #141414;
        }
      }
      .mobileView_root_n3_4_item_n1 {
        height: 240px;
        font-size: 30px;
        color: #fff;
        gap: 10px;
        margin-right: 10px;
        flex-wrap: wrap;
        justify-content: center;
        & > span {
          padding: 10px;
          width: 100%;
          background-color: #141414;
        }
      }
      .mobileView_root_n3_4_item_list_2 {
        padding-right: 10px;
        & > span {
          width: 45%;
          text-align: center;
        }
      }
    }
    .mobileView_root_n3_4_item2 {
      display: grid;
      font-size: 28px;
      color: #484848;
      border: 1px solid rgba(255, 255, 255, 0.2);
      & > div {
        font-size: 28px;
        color: #fff;
      }
    }
    .mobileView_root_n3_4_item3 {
      width: 100%;
      justify-content: center;
      font-size: 30px;
      gap: 8px;
      & > img {
        width: 50px;
        height: 70px;
      }
      & > span {
        width: 200px;
      }
    }
    .jiantou_1 {
      padding: 10px 0 0 240px;
      & > img {
        width: 40px;
        height: 37px;
      }
    }
    .jiantou {
      text-align: center;
      padding-top: 10px;
      & > img {
        width: 40px;
        height: 37px;
      }
    }
    .jiantou_3 {
      flex-direction: column;
      gap: 20px;
      padding: 0 10px;
      & > img {
        width: 40px;
        height: 37px;
      }
      & > img:nth-child(1) {
        transform: rotate(-90deg);
      }
      & > img:nth-child(2) {
        transform: rotate(90deg);
      }
    }
  }
  .mobileView_root_n3_5 {
    flex-wrap: wrap;
    gap: 40px;
    & > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 500px;
      border: 1px solid rgba(85, 255, 153, 0.39);
      box-sizing: border-box;
      padding: 20px;
      text-align: center;
      & > img {
        width: 100px;
        height: 100px;
      }
      & .t1 {
        font-size: var(--t-fontSize3);
      }
      & .t2 {
        font-size: var(--t-fontSize3);
        padding: 10px 0 50px;
      }
      & .t3 {
        font-size: var(--d-fontSize3);
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .mobileView_root_n3_6 {
    gap: 20px;
    padding: 20px 0;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 550px;
      height: 550px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      & > div {
        padding: 0 50px;
        text-align: center;
        box-sizing: border-box;
        & > img {
          width: 80px;
          height: 80px;
        }
        .t1 {
          font-size: var(--t-fontSize3);
          color: rgba(85, 255, 153, 1);
          padding: 20px 0;
        }
        .t2 {
          padding-top: 30px;
          font-size: var(--d-fontSize3);
          color: #fff;
        }
      }
    }
    & > img {
      width: 20px;
      height: 40px;
    }
    & > img:nth-of-type(2) {
      transform: rotate(180deg);
    }
  }

  .mobileView_root_n3_7 {
    padding: 60px 20px 0;
    box-sizing: border-box;

    & > div {
      margin-bottom: 40px;
      &.show_n2 {
        .mobileView_root_n3_7_n1 {
          display: none;
        }
        .mobileView_root_n3_7_n2 {
          display: flex;
        }
      }
      .mobileView_root_n3_7_n1 {
        height: 120px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 0 40px 0 20px;
        & > span {
          font-size: 32px;
          color: #fff;
          text-align: left;
        }
        & > img {
          width: 20px;
          height: 32px;
        }
      }
      .mobileView_root_n3_7_n2 {
        display: none;
        border: 1px solid rgba(85, 255, 153, 1);
        padding: 20px 40px 20px 20px;
        gap: 20px;
        & > div {
          flex: 1;
          & > div:nth-child(1) {
            display: flex;
            gap: 20px;
            align-items: center;
            font-size: 32px;
            color: rgba(85, 255, 153, 1);
            margin-bottom: 10px;
            padding-right: 5px;

            & > .b1 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-16.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b2 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-21.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b3 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-18.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b4 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-22.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b5 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-19.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b6 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-23.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b7 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-20.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b8 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-17.svg") 50% 50% / 300%
                no-repeat;
            }
            & > .b9 {
              width: 60px;
              height: 60px;
              background: url("../mobileSvg/44-52-24.svg") 50% 50% / 300%
                no-repeat;
            }
          }
          & > div:nth-child(2) {
            font-size: 28px;
            color: #fff;
            padding: 20px 0;
          }
        }
        & > img:nth-of-type(1) {
          width: 20px;
          height: 32px;
          align-self: center;
          transform: rotate(-90deg);
        }
        & > span {
          align-self: center;
        }
      }
    }
  }
  .footer_root_mobile {
    padding-top: 60px;
    .footer_root_mobile_n1 {
      img {
        width: 250px;
        height: 60px;
      }
    }
    .footer_root_mobile_n2 {
      font-size: var(--d-fontSize3);
      padding: 20px 0;
    }
    .footer_root_mobile_n3 {
      padding: 20px 0;
      gap: 50px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .footer_root_mobile_n4 {
      font-size: var(--d-fontSize3);
      gap: 50px;
      padding: 20px 0;
      & > span {
        color: rgba(160, 160, 160, 1);
      }
      & > div {
        gap: 20px;
        img {
          width: 50px;
          height: 50px;
        }
        span {
          color: #fff;
        }
      }
    }
    .footer_root_mobile_n5 {
      font-size: var(--d-fontSize3);
      padding: 20px 0 50px;
    }
  }

  .mobileView_root_n4 {
    height: 450px;
    padding-top: 100px;
    margin: unset;
    background: url("../mobileSvg/750-349-7.svg") 0 50px / 100% no-repeat;
    .mobileView_root_n4_logo {
      position: relative;
      height: 100%;
      & > img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      & > img:nth-child(1) {
        z-index: 4;
        width: 180px;
        bottom: 260px;
        animation: up 2s linear infinite;
      }
      & > img:nth-child(2) {
        z-index: 3;
        width: 330px;
        bottom: 200px;
        animation: up2 2s 1s linear infinite;
      }
      & > img:nth-child(3) {
        z-index: 2;
        width: 350px;
        bottom: 110px;
        animation: up3 2s 2s linear infinite;
      }
      & > img:nth-child(4) {
        z-index: 1;
        width: 300px;
        bottom: 80px;
      }
    }
  }
  .mobileView_root_n5 {
    padding: 100px 0 30px;
  }
  .mobileView_root_n5 {
    font-size: var(--t-fontSize);
  }
  .mobileView_root_n6 {
    font-size: var(--d-fontSize);
  }
  .mobileView_root_n7 {
    margin-top: 50px;
    & > div {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
      color: #1f1f1f;
      margin-bottom: 20px;
      & > div {
        gap: 20px;
      }
      .mobileView_root_n7_t1 {
        padding: 10px 20px;
        background-color: rgba(66, 185, 131, 0.32);
        font-size: 40px;
        color: #fff;
        border-radius: 10px;
        font-weight: bold;
      }
      .mobileView_root_n7_t2 {
        font-size: 36px;
        flex: 1;
      }
    }
    .mobileView_root_n7_t3 {
      font-size: 30px;
    }
  }
  .mobileView_root_n8 {
    font-size: var(--t-fontSize);
    color: #fff;
    padding: 150px 0 50px;
    & > span:nth-child(1) {
      color: rgba(85, 255, 153, 1);
    }
  }
  .mobileView_root_n9 {
    width: calc(100vw - 80px);
    overflow-x: auto;
    padding-bottom: 50px;
    .mobileView_root_n9_n1 {
      padding-top: 38px;
      overflow-x: auto;
      --chindWidth: 330px;
      width: calc(var(--chindWidth) * 4);
      svg {
        width: 80px;
        height: 30px;
      }
      & > div:nth-child(odd) {
        width: var(--chindWidth);
        height: 432px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 30px;
        color: #fff;
        transition: all 0.8s;
        cursor: pointer;
        &:hover {
          border-color: rgba(85, 255, 153, 1);
        }
      }
      & > div:nth-child(even) {
        padding: 0 10px;
      }
      .ContentViewN2_root_n1_2_no_border {
        border: none !important;
      }
      .ContentViewN2_root_n1_2_n2_logo {
        gap: 5px;
        padding: 11px 0;
        & > div:nth-child(1) {
          transform: rotate(90deg);
        }
        & > div:nth-child(2) {
          transform: rotate(-90deg);
        }
      }
      .ContentViewN2_root_n1_2_box {
        border: 1px solid rgba(255, 255, 255, 0.2);
        transition: all 0.8s;
        cursor: pointer;
        &:hover {
          border-color: rgba(85, 255, 153, 1);
        }
      }
      .ContentViewN2_root_n1_2_title {
        font-size: 32px;
        color: rgba(255, 255, 255, 0.5);
        padding: 20px;
        box-sizing: border-box;
      }
      .ContentViewN2_root_n1_2_n1 {
        padding: 25px 20px;
        font-size: 26px;
      }
      .ContentViewN2_root_n1_2_n2 {
        padding: 0 20px 20px;
        margin: 0 auto;
      }
      .ContentViewN2_root_n1_2_n3 {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: rgba(85, 255, 153, 1);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }
      .ContentViewN2_root_n1_2_n4 {
        height: 93px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 20px;
        transition: all 0.8s;
        box-sizing: border-box;
        padding: 0 20px;
        cursor: pointer;
        &:hover {
          border-color: rgba(85, 255, 153, 1);
        }
      }
      .ContentViewN2_root_n1_2_n5 {
        padding: 37px 0 25px;
        svg {
          width: 80px;
          height: 70px;
        }
      }
      .ContentViewN2_root_n1_2_box_n6 {
        font-size: 26px;
        padding-top: 30px;
      }
      .ContentViewN2_root_n1_2_n6 {
        margin-top: 20px;
      }
      .ContentViewN2_root_n1_2_list {
        box-sizing: border-box;
        flex-direction: column;
        padding: 30px 16px 12px;
        gap: 9px;
        & > span {
          background-color: rgba(20, 20, 20, 0.5);
          color: #fff;
          font-size: 26px;
          height: 40px;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
  .mobileView_root_n10 {
    font-size: var(--t-fontSize2);
    padding-top: 150px;
    & > span {
      color: rgba(85, 255, 153, 1);
    }
  }
  .mobileView_root_n11 {
    font-size: var(--d-fontSize2);
    padding: 0 0 60px;
    margin: 30px;
  }
}
.mobileView_root_n12 {
  & > div {
    box-sizing: border-box;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 30px;
    border-radius: 10px;
    .mobileView_root_n12_n1 {
      padding-bottom: 30px;
      & > img {
        width: 60px;
        height: 60px;
      }
      & > div {
        font-size: 48px;
        border-left: 6px solid #f0f;
        padding-left: 20px;
        &.c1 {
          border-color: rgb(34, 204, 238);
        }
        &.c2 {
          border-color: rgb(153, 69, 255);
        }
        &.c3 {
          border-color: rgb(255, 116, 74);
        }
        &.c4 {
          border-color: rgb(137, 248, 202);
        }
      }
    }
    .mobileView_root_n12_n2 {
      & > div:nth-child(1) {
        font-size: 38px;
        padding-bottom: 10px;
      }
      & > div:nth-child(2) {
        font-size: 28px;
        color: rgb(208, 208, 220);
      }
    }
  }
}

.mobileView_root_n13 {
  padding: 150px 0 50px;
  & > svg {
    width: 160px;
    height: 160px;
  }
}
.mobileView_root_n14 {
  font-size: var(--t-fontSize2);
  & > span {
    color: rgba(85, 255, 153, 1);
  }
}
.mobileView_root_n14_1 {
  font-size: var(--t-fontSize2);
  & > span {
    color: rgba(85, 255, 153, 1);
  }
}
.mobileView_root_n15 {
  font-size: var(--d-fontSize2);
  padding: 30px 0 70px;
}
.mobileView_root_n16 {
  font-size: var(--t-fontSize2);
  padding-top: 70px;
}
.mobileView_root_n {
  gap: 20px;
  padding-bottom: 100px;
  & > img {
    width: 60px;
    height: 40px;
  }
  .mobileView_root_n_list {
    width: 100%;
    height: 600px;
    position: relative;

    & > div {
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 30px;
      box-sizing: border-box;
      background-color: #1f1f1f;
      box-shadow: 0 0 10px 1px inset #42b983;
      border-radius: 4px;
      overflow-y: auto;
      opacity: 0;
      transition: opacity 0.7s;
      &.active {
        opacity: 1;
      }
      .mobileView_root_n_list_t {
        font-size: 36px;
      }
      .mobileView_root_n_list_d {
        font-size: 26px;
        padding-top: 30px;
        color: rgb(208, 208, 220);
      }
    }
  }
}
.mobileView_root_n17 {
  .mobileView_root_n17_logo {
    svg {
      width: 300px;
      height: 100px;
    }
  }
  .mobileView_root_n17_t {
    font-size: 40px;
  }
  .mobileView_root_n17_t2 {
    font-size: 22px;
    padding: 20px 0;
  }
  .mobileView_root_n17_t3 {
    gap: 20px;
    font-size: 40px;
  }
  .mobileView_root_n17_t4 {
    padding: 20px 0;
    & > div:nth-child(2) {
      gap: 20px;
    }
  }
}
@keyframes up {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes up2 {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-3px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes up3 {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-3px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}
</style>
