<script setup></script>
<template>
  <svg viewBox="0 0 233 365" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 625">
      <g id="Group 621">
        <path
          id="Vector"
          d="M65.0652 309.759L109.862 257.063L79.4355 161.005L65.0535 309.749L65.0652 309.759Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M208.959 271.112L109.863 257.065L65.0668 309.761L208.959 271.112Z"
          fill="url(#paint0_linear_153_208)"
        />
        <path
          id="Vector 9"
          d="M167.651 188.468L208.092 270.485L109.614 256.739L80.1924 161.759L167.651 188.468Z"
          stroke="white"
        />
      </g>
      <g id="Group 622">
        <g id="Group 620">
          <path
            id="Vector_3"
            d="M143.489 126.241L85.8558 102.452L130.518 8.66888L143.489 126.241Z"
            stroke="white"
          />
          <path
            id="Vector_4"
            d="M195.547 90.3501L144.485 126.131L131.514 8.55923L195.547 90.3501Z"
            stroke="white"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_208"
        x1="168.008"
        y1="236.3"
        x2="92.3274"
        y2="333.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565B63" />
        <stop offset="1" stop-color="#616161" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 91px;
  height: 142px;
}
</style>
