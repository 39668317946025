export default {
  home: "ホーム",
  about: "約",
  document: "文書",
  t1: "引入去中心化通用計算的",
  t2: "エンタープライズ汎用コンピューティング・ブロックチェーン",
  t3: "イーサネット初のモジュール化された汎用コンピューティング・コプロセッサの分散型ネットワーク",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "エンタープライズクラスのアプリケーションのコンピューティング・ニーズを満たす",
  t10: "より速く、より強く、より安全な分散型汎用コンピューティング・ネットワーク",
  t11: "開発者と企業は、高性能アプリケーションを自由に構築し、展開することができます。",
  t12: "高速より高速",
  t13: "最大300msのブロック検証速度、最大20,000のTPS",
  t14: "低いコンピューティングコスト",
  t15: "分散型大規模オフチェーンコンピューティングソリューションは、企業のコンピューティングパワーのコストを効果的に削減します。",
  t16: "企業レベルのプライバシー・セキュリティ",
  t17: "企業のプライバシーと機密性の高い計算は、オフチェーンコンピューティングによって保護されます。",
  t18: "一般的なコンピューティング能力",
  t19: "より高度なスケーリング・ソリューションにより、イーサネットはエンタープライズ・アプリケーションのコンピューティング・ニーズにも対応できます。",
  t20: "分散化と透過性",
  t21: "コンセンサスレイヤーを導入したイーサネット初の分散型レイヤー2ネットワーク",
  t22: "互換性と拡張性",
  t23: "Type1イーサネットとの互換性に加え、zkVMベースの開発言語のすべての機能と高度な機能をサポート。",
  t24: "ENIACコア・アーキテクチャ",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "ENIACアーキテクチャに基づくコア・アプリケーション・モジュール",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "ゼロ知識機械学習",
  t38: "ゼロ知識証明と機械学習を組み合わせることで、データのプライバシーと信頼性を保護しながら効率を向上。",
  t39: "ゼロ知識分散メタバース",
  t40: "メタバースに信頼できる環境と強力で低コストのコンピューティング・インフラを提供する。",
  t41: "ゼロ知識分散型アイデンティティ",
  t42: "分散型ネットワークとゼロ知識認証に基づく信頼できるデジタルIDをユーザーに提供します。",
  t43: "サービスとしてのロールアップ ",
  t44: "zkVMベースのエンタープライズロールアップの迅速な展開をサポートします。",
  t45: "ENIACを探る",
  t46: "分散型汎用コンピューティングの無限の可能性を探る",
  t47: "l 標準化されたエンタープライズ・グレードのブロックチェーン・アプリケーション・ソリューション",
  t48: "スタートアップのための迅速なデプロイメント、オンデマンド",
  t49: "l モジュール化され、迅速にカスタマイズされたエンタープライズ・ブロックチェーン製品とサービス",
  t50: "中小企業向け、柔軟なオプション、迅速な適応",
  t51: "l AppRollupsに基づく詳細なカスタマイズ",
  t52: "大企業および超大企業向け、徹底したカスタマイズのフルレンジ",
  t53: "企業データコラボレーションプライバシーとセキュリティ",
  t54: "ENIACは、企業間の機密データの完全保持をサポートしながら、チェーン上のデータ相互作用とビジネス協力データを実現します。",
  t55: "サプライチェーン管理",
  t56: "ENIACを使用することで、企業は詳細を明らかにすることなく、チェーン上の重要なリンクデータの結果を検証するだけで、信頼できるサプライチェーンのブロックチェーン管理システムを迅速に構築することができます。",
  t57: "革新的な金融と製品",
  t58: "ENIACは、ゼロ知識証明のオフチェーン・コンピューティング・ソリューションに基づき、プライバシー保護された信用評価、ゼロ知識資産証明などの新しい金融サービスを開発するお客様をサポートします。",
  t59: "スマートシティとIoTセキュリティ",
  t60: "スマートシティとIoTシナリオにおいて、データの可用性を向上させながら、すべてのポートでデバイスデータのプライバシーを効果的に保護します。",
  t61: "デジタルIDアプリケーション",
  t62: "ユーザー情報のプライバシーを犠牲にすることなく、デジタルIDの使いやすさを向上させ、ユーザーに利便性をもたらします。",
  t63: "持続可能な資源管理",
  t64: "企業や組織は、エネルギーのような重要な資源を効果的に追跡し、効率的に割り当てることができます。",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "事業ポートフォリオ",
  "t102-1": "我們的業務组合",
  t103: "健康と医療研究",
  "t103-1":
    "ENIACは、患者やユーザーのプライバシーを保護し、研究チームが個人情報にアクセスすることなく、効果的な医学研究を行うことを可能にします。",
  t104: "著作権およびコンテンツ作成の保護",
  "t104-1":
    "ENIACによって、市場はより効率的な著作権保護とライセンス供与のメカニズムを確立し、健全な知的財産権の実現と流通を実現することができます。",
  t105: "完全非公開のオンライン投票とリサーチ市場。",
  "t105-1":
    "ゼロ・プルーフ・オブ・ナレッジのダウンチェーンコンピューティングに基づき、ユーザーは研究データを開示したり、触れたりすることなく、より詳細な分析結果を得ることができる。",
};
