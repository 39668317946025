<script setup></script>
<template>
  <svg viewBox="0 0 292 182" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Rectangle 143"
      d="M142.634 1.76065C144.61 0.900771 146.854 0.90216 148.829 1.76449L286.752 61.9981C292.127 64.3456 293.048 71.5821 288.431 75.2003L157.952 177.46C150.781 183.08 140.696 183.065 133.541 177.425L3.39076 74.8158C-1.21096 71.1878 -0.276942 63.9622 5.09604 61.6236L142.634 1.76065Z"
      fill="url(#paint0_linear_153_350)"
      stroke="url(#paint1_linear_153_350)"
      stroke-width="0.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_153_350"
        x1="146.002"
        y1="114.32"
        x2="148.264"
        y2="176.461"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003396" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_153_350"
        x1="145.978"
        y1="6.3114"
        x2="145.978"
        y2="211.528"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 2.17rem;
  height: 2.16rem;
}
</style>
