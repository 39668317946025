<script setup></script>
<template>
  <svg viewBox="0 0 140 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 622">
      <g id="Group 620">
        <path
          id="Vector"
          d="M76.378 140.494L11.7517 113.819L61.8327 8.65747L76.378 140.494Z"
          stroke="white"
        />
        <path
          id="Vector_2"
          d="M134.63 100.262L77.3738 140.385L62.8284 8.54785L134.63 100.262Z"
          stroke="white"
        />
      </g>
    </g>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 54px;
  height: 58px;
}
</style>
