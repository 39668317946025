<script setup></script>
<template>
  <svg viewBox="0 0 61 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 650">
      <path
        id="Vector"
        d="M30.4122 99.9998V75.6981L0.40625 56.9067L30.4068 99.9998H30.4122Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M60.1358 56.9067L30.4102 75.6981V99.9998L60.1358 56.9067Z"
        fill="url(#paint0_linear_153_410)"
      />
      <path
        id="Vector 9"
        d="M30.2722 44.2643L59.6056 56.949L30.2722 75.5797L0.938803 56.949L30.2722 44.2643Z"
        stroke="white"
        stroke-width="0.5"
      />
      <path
        id="Vector_3"
        d="M0 49.1201L30.4095 0V65.7858L0 49.1201Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M30.4102 65.7858V0L60.8196 49.1201L30.4102 65.7858Z"
        fill="url(#paint1_linear_153_410)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_410"
        x1="41.2506"
        y1="56.9067"
        x2="43.0608"
        y2="100.093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565B63" />
        <stop offset="1" stop-color="#616161" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_153_410"
        x1="41.5"
        y1="1.61373e-08"
        x2="45.6149"
        y2="65.7858"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565B63" />
        <stop offset="1" stop-color="#616161" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 24px;
  height: 39px;
}
</style>
