<script setup></script>
<template>
  <svg viewBox="0 0 158 162" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_153_506)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.6863 101L69.6957 96.9454C63.8783 94.3012 60 88.4837 60 82.1375V64.1563L61.0577 63.6275C72.1637 57.4575 85.3851 57.4575 96.4911 63.6275L97.3725 64.1563V81.9612C97.3725 88.3075 93.6705 94.1249 87.6768 96.7691L78.6863 101ZM63.702 66.4481V82.1375C63.702 87.0735 66.6988 91.4806 71.106 93.596L78.6863 96.9454L86.2665 93.596C90.8499 91.6569 93.6705 87.0735 93.6705 82.1375V66.4481C84.1511 61.6884 73.2214 61.6884 63.702 66.4481ZM78.686 88.4839C73.0449 88.4839 68.4614 83.9005 68.4614 78.2594C68.4614 72.6182 73.0449 68.0348 78.686 68.0348C84.3271 68.0348 88.9106 72.6182 88.9106 78.2594C88.9106 83.9005 84.3271 88.4839 78.686 88.4839ZM78.686 70.8554C74.6314 70.8554 71.282 74.2048 71.282 78.2594C71.282 82.3139 74.6314 85.6634 78.686 85.6634C82.7406 85.6634 86.09 82.3139 86.09 78.2594C86.09 74.2048 82.7406 70.8554 78.686 70.8554ZM78.8634 81.9611H80.8026V80.1983H78.8634C77.8057 80.1983 76.9243 79.3169 76.9243 78.2591C76.9243 77.2014 77.8057 76.4963 78.8634 76.4963H80.8026V74.5571H78.8634C76.748 74.5571 75.1615 76.1437 75.1615 78.2591C75.1615 80.3746 76.748 81.9611 78.8634 81.9611Z"
        fill="#55FF99"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_506"
        x="0"
        y="0"
        width="157.373"
        height="162"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="30" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0.678431 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_506"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_506"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 15px;
  height: 16px;
  transform: scale(7);
}
</style>
