<script setup></script>
<template>
  <svg
    width="80"
    height="68"
    viewBox="0 0 80 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.0103 67.2578C39.434 67.2504 38.8661 67.1182 38.3457 66.8704C37.8253 66.6226 37.3648 66.2651 36.9958 65.8223L1.86942 26.088C0.87867 25.0494 0.325928 23.6691 0.325928 22.2337C0.325928 20.7983 0.87867 19.4181 1.86942 18.3795L16.8846 2.10104C17.4509 1.47853 18.1398 0.979908 18.9081 0.636521C19.6764 0.293135 20.5075 0.112412 21.349 0.105713H58.6716C59.5146 0.116412 60.3465 0.298871 61.1166 0.641942C61.8866 0.985012 62.5786 1.48149 63.1503 2.10104L78.1942 18.3795C79.191 19.4364 79.7365 20.8403 79.7151 22.2929C79.6936 23.7456 79.1069 25.1327 78.0794 26.1598L43.0392 65.8941C42.6661 66.3358 42.2027 66.6924 41.6803 66.94C41.1578 67.1876 40.5884 67.3204 40.0103 67.3295V67.2578ZM5.01313 21.2935C4.889 21.4151 4.79038 21.5603 4.72306 21.7205C4.65574 21.8807 4.62106 22.0528 4.62106 22.2266C4.62106 22.4003 4.65574 22.5724 4.72306 22.7326C4.79038 22.8928 4.889 23.038 5.01313 23.1596L40.0103 62.8078L74.9213 23.2458C75.0601 23.1267 75.1731 22.9807 75.2537 22.8166C75.3343 22.6525 75.3807 22.4737 75.3901 22.2912C75.3995 22.1086 75.3717 21.926 75.3084 21.7545C75.2451 21.583 75.1476 21.4262 75.0218 21.2935L59.9779 4.97201C59.8088 4.79599 59.606 4.65573 59.3816 4.55956C59.1572 4.46339 58.9157 4.41327 58.6716 4.41217H21.349C21.0954 4.40714 20.8436 4.45424 20.609 4.55054C20.3744 4.64684 20.1621 4.7903 19.9853 4.97201L5.01313 21.2935Z"
      fill="white"
    />
    <path
      d="M40.0103 45.6968C39.7069 45.6972 39.4068 45.6334 39.1297 45.5098C38.8527 45.3861 38.6049 45.2052 38.4026 44.979L23.3874 28.0834C23.1989 27.8713 23.054 27.6242 22.961 27.3561C22.868 27.088 22.8287 26.8043 22.8453 26.521C22.862 26.2377 22.9343 25.9605 23.0581 25.7052C23.1819 25.4499 23.3547 25.2215 23.5668 25.033C23.9951 24.6522 24.5571 24.4573 25.1292 24.4909C25.4124 24.5076 25.6896 24.5799 25.945 24.7037C26.2003 24.8274 26.4287 25.0003 26.6172 25.2124L40.0103 40.2994L53.4178 25.2267C53.6063 25.0156 53.8345 24.8437 54.0895 24.7208C54.3444 24.5978 54.6211 24.5263 54.9037 24.5103C55.1863 24.4943 55.4693 24.5342 55.7365 24.6275C56.0037 24.7209 56.2499 24.866 56.461 25.0545C56.6721 25.243 56.8441 25.4712 56.967 25.7262C57.0899 25.9812 57.1614 26.2578 57.1774 26.5404C57.1934 26.823 57.1536 27.106 57.0602 27.3732C56.9668 27.6404 56.8218 27.8866 56.6333 28.0977L41.6181 44.979C41.4164 45.2059 41.1687 45.3873 40.8915 45.511C40.6143 45.6348 40.3139 45.6981 40.0103 45.6968Z"
      fill="white"
    />
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 19px;
  height: 31px;
}
</style>
