export default {
  home: "Inicio",
  about: "Acerca de",
  document: "Documento",
  t1: "引入去中心化通用計算的",
  t2: "Blockchain de computación de propósito general para empresas",
  t3: "La primera red descentralizada de coprocesadores informáticos de propósito general modularizados de Ethernet",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "Satisfacción de las necesidades informáticas de las aplicaciones empresariales",
  t10: "Una red descentralizada de computación de propósito general más rápida, sólida y segura",
  t11: "Los desarrolladores y las empresas tienen libertad para crear e implantar aplicaciones de alto rendimiento.",
  t12: "más rápido que rápido",
  t13: "Velocidad de validación de bloques de hasta 300 ms, TPS de hasta 20.000",
  t14: "Bajos costes de computación",
  t15: "La solución informática descentralizada a gran escala fuera de la cadena reduce eficazmente el coste de la potencia de cálculo para las empresas.",
  t16: "Seguridad de la privacidad a nivel empresarial",
  t17: "La privacidad de la empresa y los cálculos sensibles están protegidos por la computación fuera de la cadena.",
  t18: "Capacidad informática general",
  t19: "Con soluciones de escalado más avanzadas, Ethernet también puede satisfacer las necesidades informáticas de las aplicaciones empresariales",
  t20: "Descentralizada y transparente",
  t21: "La primera red descentralizada de capa 2 en Ethernet que introduce una capa de consenso",
  t22: "Compatibilidad y extensibilidad",
  t23: "Soporta compatibilidad con Type1 Ethernet, así como toda la funcionalidad y características avanzadas del lenguaje de desarrollo basado en zkVM.",
  t24: "Arquitectura del núcleo de ENIAC",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "Módulos centrales de aplicación basados en la arquitectura ENIAC",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "Aprendizaje automático de conocimiento cero ",
  t38: "Pruebas de conocimiento cero combinadas con aprendizaje automático para mejorar la eficiencia al tiempo que se protege la privacidad y la fiabilidad de los datos.",
  t39: "Metaverso Descentralizado de Conocimiento Cero",
  t40: "Proporciona un entorno de confianza y una potente infraestructura informática de bajo coste para el metaverso.",
  t41: "Identidad descentralizada de conocimiento cero",
  t42: "Proporciona a los usuarios una identidad digital de confianza basada en una red descentralizada y una autenticación de conocimiento cero.",
  t43: "Rollup como servicio ",
  t44: "Soporta el despliegue rápido de Rollup empresarial basado en zkVM.",
  t45: "Explorar ENIAC",
  t46: "Explorando las ilimitadas posibilidades de la informática descentralizada de propósito general",
  t47: "l Soluciones de aplicaciones blockchain estandarizadas de nivel empresarial",
  t48: "Despliegue rápido, bajo demanda para startups",
  t49: "l Productos y servicios blockchain empresariales modularizados y personalizados rápidamente",
  t50: "Para PYMES, opciones flexibles, adaptación rápida",
  t51: "l Personalización profunda basada en AppRollups",
  t52: "Para empresas grandes y ultra grandes, gama completa de personalización en profundidad",
  t53: "Colaboración de datos empresariales Privacidad y seguridad",
  t54: "ENIAC apoya la retención completa de datos confidenciales entre las empresas al tiempo que realiza interacciones de datos y datos de cooperación empresarial en la cadena.",
  t55: "Gestión de la cadena de suministro",
  t56: "Con ENIAC, las organizaciones pueden construir rápidamente un sistema de gestión blockchain de la cadena de suministro de confianza sin revelar detalles, simplemente validar los resultados de los datos de enlace críticos en la cadena.",
  t57: "Finanzas y productos innovadores",
  t58: "ENIAC apoya a los clientes para desarrollar nuevos servicios financieros, como la evaluación de crédito con protección de la privacidad, la certificación de activos de conocimiento cero, etc., basados en la solución de computación fuera de la cadena de prueba de conocimiento cero.",
  t59: "Ciudad inteligente y seguridad IoT",
  t60: "Protege eficazmente la privacidad de los datos de los dispositivos en todos los puertos en escenarios de ciudades inteligentes e IoT, al tiempo que mejora la disponibilidad de los datos.",
  t61: "Aplicaciones de identidad digital",
  t62: "Mejorar la usabilidad de las identidades digitales sin sacrificar la privacidad de la información del usuario, aportando más comodidad a los usuarios.",
  t63: "Gestión sostenible de recursos",
  t64: "Las empresas u organizaciones pueden realizar un seguimiento eficaz y asignar eficientemente recursos críticos como la energía, mientras que cada eslabón de los datos puede verificarse a lo largo de la cadena.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "Cartera de negocios",
  "t102-1": "我們的業務组合",
  t103: "Salud e investigación médica",
  "t103-1":
    "ENIAC protege la privacidad de pacientes y usuarios, lo que permite llevar a cabo investigaciones médicas eficaces sin que el equipo de investigación tenga acceso a esta información privada.",
  t104: "Derechos de autor y protección de la creación de contenidos",
  "t104-1":
    "Con ENIAC, el mercado puede establecer un mecanismo más eficaz de protección de los derechos de autor y de concesión de licencias, logrando una realización y circulación sanas de los derechos de propiedad intelectual.",
  t105: "Mercado de votación e investigación en línea completamente privado.",
  "t105-1":
    "Basándose en la computación en cadena descendente de prueba de conocimiento cero, los usuarios pueden obtener resultados de análisis más profundos sin revelar ni tocar los datos de la investigación.",
};
