<script setup>
import s1 from "../svg/SvgView1.vue";
import logo_nav from "@/views/home/mobileSvg/144-30-3.svg";
// import s2 from "../svg/SvgView2.vue";
import { ref, effect } from "vue";
import { useRouter, useRoute } from "vue-router";
// import { useStore } from "vuex";
// import { useI18n } from "vue-i18n";
// const i18 = useI18n();
import store from "@/store";

const router = useRouter();
const route = useRoute();
const list = ref([
  {
    label: "home",
    path: "/",
  },
  // {
  //   label: "about",
  //   path: "/about",
  // },
  {
    label: "document",
    path: "/eniac_whitepaper_{lang}.pdf",
  },
]);

// const langList = [
//   {
//     label: "English",
//     code: "en",
//   },
//   {
//     label: "简体中文",
//     code: "zh",
//   },
//   // {
//   //   label: "한국어",
//   //   code: "kr",
//   // },
//   // {
//   //   label: "日本語",
//   //   code: "jp",
//   // },
//   // {
//   //   label: "Español",
//   //   code: "es",
//   // },
//   // {
//   //   label: "Türkçe",
//   //   code: "tr",
//   // },
//   // {
//   //   label: "Deutsch",
//   //   code: "de",
//   // },
//   // {
//   //   label: "العربية",
//   //   code: "ar",
//   // },
//   // {
//   //   label: "Italiano",
//   //   code: "it",
//   // },
//   // {
//   //   label: "Русский",
//   //   code: "ru",
//   // },
// ];

// const store = useStore();

// const language = ref(store.getters.getLanguage);

// const languageName = ref(
//   langList.find((val) => val.code === language.value)?.label ?? "简体中文"
// );

const listIndex = ref(-1);
// const show = ref(false);
const showMenu = ref(false);

// const changeLanguage = (item) => {
//   i18.locale.value = item.code;
//   store.commit("setLanguage", item.code);
//   languageName.value = item.label;
//   language.value = item.code;
//   show.value = false;
// };

const toDetails = (path) => {
  if (route.path === path) {
    return;
  } else if (path.substring(0, 17) == "/eniac_whitepaper") {
    const selectedLang = store.getters.getLanguage;
    const whitepaper_url = path.replace("{lang}", selectedLang);
    window.open(whitepaper_url);
  } else if (path.indexOf(0) != "/") {
    window.open(path);
  } else {
    router.push({
      path,
    });
    showMenu.value = false;
  }
};

effect(() => {
  listIndex.value = list.value.findIndex((val) => val.path === route.path);
});
</script>
<template>
  <div>
    <div class="nav_root d-flex f-j-sb f-a-center">
      <div class="nav_root_left">
        <div>
          <s1></s1>
        </div>
      </div>
      <div class="nav_root_center d-flex f-a-center f-j-center">
        <div
          v-for="(item, index) in list"
          :class="{ active: listIndex === index }"
          class="c-p"
          :key="index"
          @click="toDetails(item.path)"
        >
          {{ $t(item.label) }}
        </div>
      </div>
      <div class="nav_root_right d-flex c-p">
        <!-- <div class="d-flex f-a-center" @click="show = !show">
        <span>{{ languageName }}</span>
        <s2></s2>
      </div>
      <div class="nav_root_right_list" :class="{ show: show }">
        <div
          :class="{ active: language === item.code }"
          class="c-p"
          v-for="(item, index) in langList"
          :key="index"
          @click="changeLanguage(item)"
        >
          {{ item.label }}
        </div>
      </div> -->
      </div>
    </div>
    <div class="nav_root_mobile">
      <div class="d-flex f-j-sb">
        <div class="nav_root_mobile_n1 d-flex f-a-center">
          <img :src="logo_nav" alt="" />
        </div>
        <div class="nav_root_mobile_n2 d-flex f-a-center">
          <!-- <div class="nav_root_mobile_n2_n1 d-flex f-a-center">
            <span @click="show = !show">{{ languageName }}</span>
            <img src="../mobileSvg/9-7-6.png" alt="" />
            <div class="nav_root_mobile_n2_n1_n1" :class="{ show: show }">
              <div
                class="t-center"
                :class="{ active: language === item.code }"
                v-for="(item, index) in langList"
                :key="index"
                @click="changeLanguage(item)"
              >
                {{ item.label }}
              </div>
            </div>
          </div> -->
          <img @click="showMenu = true" src="../mobileSvg/36-33-1.png" alt="" />
        </div>
      </div>
      <div v-show="showMenu" class="nav_root_mobile_menu">
        <div class="nav_root_mobile_menu_n1">
          <img
            @click="showMenu = false"
            src="../mobileSvg/27-27-4.png"
            alt=""
          />
        </div>
        <div class="nav_root_mobile_menu_n2">
          <div class="nav_root_mobile_menu_n2_list">
            <div
              class="t-center"
              v-for="(item, index) in list"
              :key="index"
              @click="toDetails(item.path)"
            >
              {{ $t(item.label) }}
            </div>
          </div>
          <!-- <div class="nav_root_mobile_menu_n2_btn d-flex f-j-sb">
            <div class="d-flex f-j-center f-a-center">
              <img src="../mobileSvg/28-28-5.png" alt="" />
              <span>Twitter</span>
            </div>
            <div class="d-flex f-j-center f-a-center">
              <img src="../mobileSvg/28-28-6.png" alt="" />
              <span>Discord</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.nav_root {
  color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 47px;
  font-size: 9px;
  position: fixed;
  z-index: 22;
  background-color: #000;
  & > div {
    flex: 1;
    box-sizing: border-box;
  }
  .nav_root_left {
    & > div {
      padding-left: 39px;
      width: 75px;
      height: 16px;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav_root_center {
    gap: 39px;
    font-family: "Archivo", sans-serif;
    & > div {
      transition: color 0.3s;
      &:hover,
      &.active {
        color: rgba(85, 255, 153, 1);
      }
    }
  }
  .nav_root_right {
    justify-content: flex-end;
    position: relative;
    .nav_root_right_list {
      position: absolute;
      right: 4px;
      top: 14px;
      height: 0;
      overflow: hidden;
      transition: height 0.3s;
      background-color: #1f1f1f;
      &.show {
        height: auto;
      }
      & > div {
        font-size: 8px;
        height: 14px;
        line-height: 14px;
        border: 1px solid rgba(255, 255, 255, 0.23);
        padding: 0 4px;
        position: all 0.8s;
        &:hover,
        &.active {
          background-color: rgba(255, 255, 255, 0.23);
          color: #61ff71;
        }
      }
    }
    & > div {
      padding-right: 27px;
      & > span {
        font-size: 8px;
      }
      & > svg {
        width: 11px;
        height: 11px;
      }
    }
  }
}
.nav_root_mobile {
  display: none;
  // position: fixed;
  z-index: 999;
  width: 100%;
  height: 88px;
  background-color: #000;
  & > div {
    align-items: center;
    padding: 20px;
    height: 100%;
    .nav_root_mobile_n1 {
      height: 100%;
      padding-left: 20px;
      & > img {
        width: 240px;
        height: 100px;
      }
    }
    .nav_root_mobile_n2 {
      gap: 40px;
      padding-right: 20px;
      .nav_root_mobile_n2_n1 {
        gap: 10px;
        position: relative;
        span {
          font-size: 20px;
          color: #fff;
        }
        img {
          width: 9px;
          height: 7px;
        }
        .nav_root_mobile_n2_n1_n1 {
          position: absolute;
          left: 0;
          top: 40px;
          width: 100%;
          display: none;
          border-radius: 8px;
          border: 1px solid rgb(160, 155, 155);
          background-color: rgb(73, 72, 72);
          color: #fff;
          font-size: 20px;
          & > div {
            padding: 20px 0;
            &.active {
              color: #42b983;
            }
          }
          &.show {
            display: block;
          }
        }
      }
      & > img {
        width: 36px;
        height: 33px;
      }
    }
  }
  .nav_root_mobile_menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 360px;
    background-color: #1f1f1f;
    padding: unset;
    .nav_root_mobile_menu_n1 {
      text-align: right;
      padding: 40px;
      img {
        width: 27px;
        height: 27px;
      }
    }
    .nav_root_mobile_menu_n2 {
      .nav_root_mobile_menu_n2_list {
        box-sizing: border-box;
        padding: 0 100px;
        & > div {
          height: 70px;
          line-height: 70px;
          font-size: 30px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          color: #fff;
          margin-bottom: 40px;
        }
      }
      .nav_root_mobile_menu_n2_btn {
        padding: 20px 130px;
        & > div {
          font-size: 20px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          color: #fff;
          gap: 20px;
          width: 180px;
          height: 52px;
          & > img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }

  .nav_root_mobile_box {
    .nav_root_mobile_box_n1 {
      width: 100%;
      height: 146px;
      .nav_root_mobile_box_n1_logo {
        width: 300px;
        height: 90px;
        padding-left: 40px;
        & > svg {
          width: 100%;
          height: 100%;
        }
      }
      .nav_root_mobile_box_n1_logo_btn {
        margin: 0 60px 0 auto;
        gap: 40px;
        & > div {
          width: 50px;
          height: 50px;
          & > svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .nav_root_mobile_box_n2 {
      height: 0;
      overflow: hidden;
      transition: height 0.3s;
      & > div {
        color: #b0b5c3;
        & > div {
          height: 100px;
          line-height: 100px;
          padding-left: 20px;
          border-bottom: 1px solid #494a50;
          font-size: 40px;
        }
      }
      .nav_root_mobile_box_n2_language {
        & > div {
          font-size: 34px;
        }
        & > div.active {
          color: #42b983;
        }
      }
    }
    .show {
      height: auto;
    }
  }
}

@media (max-width: 756px) {
  // 从最小尺寸 576px 开始应用样式（设备的最小屏幕尺寸）
  .nav_root {
    display: none;
  }
  .nav_root_mobile {
    display: block;
  }
}
</style>
