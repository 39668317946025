<script setup></script>
<template>
  <svg
    width="67"
    height="76"
    viewBox="0 0 67 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.5366 1.81946L64.6255 19.7688V55.6675L33.5366 73.6159L2.44775 55.6675V19.7688L33.5366 1.81946Z"
      stroke="#fff"
      stroke-width="3"
    />
    <path
      d="M33.5366 20.3169L39.1907 31.7732L51.833 33.6099L42.6848 42.527L44.8449 55.1186L33.5366 49.1745L22.2283 55.1186L24.3884 42.527L15.2402 33.6099L27.8825 31.7732L33.5366 20.3169Z"
      fill="#fff"
    />
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 19px;
  height: 31px;
}
</style>
