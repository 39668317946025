<script setup></script>
<template>
  <svg viewBox="0 0 292 283" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 638">
      <path
        id="Vector"
        d="M180.36 245.678L150.066 155.309L238.221 48.0258L180.38 245.671L180.36 245.678Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M16.1006 122.488L150.064 155.31L180.359 245.679L16.1006 122.488Z"
        fill="url(#paint0_linear_153_357)"
      />
      <path
        id="Vector 9"
        d="M111.234 37.7474L17.1378 122.229L150.619 155.233L237.238 48.4442L111.234 37.7474Z"
        stroke="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_357"
        x1="86.3273"
        y1="98.9458"
        x2="133.431"
        y2="261.794"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565B63" />
        <stop offset="1" stop-color="#616161" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 92px;
  height: 86px;
}
</style>
