<script setup></script>
<template>
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 517">
      <rect
        id="Rectangle 57"
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        fill="black"
        stroke="#42FFAD"
      />
      <g id="Clip path group">
        <mask
          id="mask0_153_143"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="7"
          y="7"
          width="16"
          height="17"
        >
          <g id="clippath">
            <path id="Vector" d="M23 7H7V23.7323H23V7Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask0_153_143)">
          <g id="Group">
            <path
              id="Vector_2"
              d="M11.8015 15.2262L18.5607 7.0105L17.0511 13.3993L19.6769 15.2244L10.9285 23.4407L14.4268 16.1377L11.8015 15.2264V15.2262ZM18.8855 8.74179C21.3287 10.109 22.983 12.7179 22.9836 15.7171C22.9846 20.1312 19.407 23.7106 14.9927 23.7118C14.0162 23.7118 13.0857 23.5281 12.2201 23.2076L14.0521 21.4857C14.3626 21.5389 14.6771 21.5667 14.9921 21.5691C18.2176 21.5691 20.8413 18.9432 20.8407 15.7177C20.8392 13.8197 19.9159 12.0405 18.3644 10.9469L18.8853 8.74179H18.8855ZM9.14096 15.7196C9.14096 17.666 10.101 19.3893 11.5677 20.4519L10.6296 22.4119C8.44514 20.9862 6.99866 18.5234 6.99805 15.7202C6.99703 11.3059 10.5746 7.7263 14.9889 7.7263C15.6188 7.72813 16.2464 7.80438 16.8584 7.95362L15.2725 9.88247C15.1783 9.87757 15.0853 9.86819 14.9897 9.86819C11.764 9.86819 9.14035 12.4941 9.14117 15.7196H9.14096Z"
              fill="#42FFAD"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 12px;
  height: 12px;
}
</style>
