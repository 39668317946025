<script setup></script>
<template>
  <svg
    width="71"
    height="68"
    viewBox="0 0 71 68"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5045 0.387207C36.5024 0.387207 37.4475 0.826386 38.0866 1.58319L52.9579 19.2523L65.5706 10.9275C66.1289 10.5601 66.7834 10.3659 67.4518 10.3694C68.1202 10.3729 68.7727 10.5739 69.3271 10.9471C70.4584 11.7098 71.0094 13.0763 70.7192 14.4017L62.316 52.6632C62.1493 53.409 61.7328 54.0755 61.1356 54.5522C60.5383 55.0289 59.7961 55.2873 59.0319 55.2846H11.977C11.2129 55.2873 10.4707 55.0289 9.87341 54.5522C9.27616 54.0755 8.85969 53.409 8.69301 52.6632L0.289788 14.4017C0.148605 13.7527 0.205853 13.0762 0.454097 12.4602C0.70234 11.8441 1.13011 11.3169 1.68183 10.9471C2.2363 10.5739 2.88875 10.3729 3.55714 10.3694C4.22553 10.3659 4.88003 10.5601 5.43838 10.9275L18.051 19.2523L32.9223 1.58319C33.5635 0.826386 34.5065 0.387207 35.5045 0.387207ZM8.0558 63.7799C8.0558 61.9762 9.59293 60.5136 11.4869 60.5136H59.5221C60.7475 60.5136 61.8807 61.1351 62.4944 62.1468C62.7952 62.6388 62.9543 63.2043 62.9543 63.7809C62.9543 64.3576 62.7952 64.9231 62.4944 65.4151C61.8807 66.4248 60.7494 67.0483 59.5221 67.0483H11.4869C9.59293 67.0483 8.0558 65.5857 8.0558 63.7799ZM30.3579 40.9073C30.3579 42.7111 31.895 44.1737 33.7889 44.1737H37.22C39.114 44.1737 40.6511 42.7111 40.6511 40.9073C40.6511 39.1036 39.114 37.639 37.22 37.639H33.7889C31.895 37.639 30.3579 39.1016 30.3579 40.9073Z"
      fill="white"
    />
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 19px;
  height: 31px;
}
</style>
