<script setup></script>
<template>
  <svg viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Union" d="M27 0L48 21L27 42V28H0V14H27V0Z" fill="#55FF99" />
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 19px;
  height: 16px;
}
</style>
