export default {
  home: "Ana Sayfa",
  about: "Hakkında",
  document: "Belge",
  t1: "引入去中心化通用計算的",
  t2: "Kurumsal Genel Amaçlı Hesaplama Blockchain",
  t3: "Ethernet'in Modülerleştirilmiş Genel Amaçlı Bilgi İşlem Ortak İşlemcilerinden Oluşan İlk Merkezi Olmayan Ağı",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "Kurumsal sınıf uygulamaların bilgi işlem ihtiyaçlarını karşılama",
  t10: "Daha Hızlı, Daha Güçlü ve Daha Güvenli Merkezi Olmayan Genel Amaçlı Bilgi İşlem Ağı",
  t11: "Geliştiriciler ve kurumlar yüksek performanslı uygulamalar geliştirmekte ve dağıtmakta özgürdür.",
  t12: "hızlıdan daha hızlı",
  t13: "300 ms'ye kadar blok doğrulama hızı, 20.000'e kadar TPS",
  t14: "Düşük Bilgi İşlem Maliyetleri",
  t15: "Merkezi olmayan büyük ölçekli zincir dışı bilgi işlem çözümü, işletmeler için bilgi işlem gücü maliyetini etkili bir şekilde azaltır.",
  t16: "Kurumsal düzeyde gizlilik güvenliği",
  t17: "Kurumsal gizlilik ve hassas hesaplamalar zincir dışı bilgi işlem ile korunur.",
  t18: "Genel Hesaplama Yeteneği",
  t19: "Daha gelişmiş ölçeklendirme çözümleri ile Ethernet, kurumsal uygulamaların bilgi işlem ihtiyaçlarını da karşılayabilir",
  t20: "Merkezi Olmayan ve Şeffaf",
  t21: "Ethernet'te bir mutabakat katmanı sunan ilk merkezi olmayan Layer2 ağı",
  t22: "Uyumluluk ve Genişletilebilirlik",
  t23: "Tip1 Ethernet uyumluluğunun yanı sıra zkVM tabanlı geliştirme dilinin tüm işlevselliğini ve gelişmiş özelliklerini destekler.",
  t24: "ENIAC Çekirdek Mimarisi",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "ENIAC mimarisine dayalı çekirdek uygulama modülleri",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "Sıfır Bilgi Makine Öğrenimi ",
  t38: "Veri gizliliğini ve güvenilirliğini korurken verimliliği artırmak için makine öğrenimi ile birleştirilmiş sıfır bilgi kanıtlama.",
  t39: "Sıfır Bilgi Merkezi Olmayan Metaverse",
  t40: "Metaverse için güvenilir bir ortam ve güçlü, düşük maliyetli bilgi işlem altyapısı sağlar",
  t41: "Sıfır Bilgi Merkezi Olmayan Kimlik",
  t42: "Kullanıcılara merkezi olmayan bir ağa ve sıfır bilgi kimlik doğrulamasına dayalı güvenilir bir dijital kimlik sağlar.",
  t43: "Hizmet Olarak Toplama ",
  t44: "zkVM tabanlı kurumsal Rollup'ın hızlı dağıtımını destekler.",
  t45: "ENIAC'ı Keşfedin",
  t46: "Merkezi Olmayan Genel Amaçlı Bilgi İşlemin Sınırsız Olanaklarını Keşfetmek",
  t47: "l Standartlaştırılmış kurumsal sınıf blockchain uygulama çözümleri",
  t48: "Startup'lar için Talep Üzerine Hızlı Dağıtım",
  t49: "l Modülerleştirilmiş ve Hızla Özelleştirilmiş Kurumsal Blockchain Ürün ve Hizmetleri",
  t50: "KOBİ'ler için esnek seçenekler, hızlı adaptasyon",
  t51: "l AppRollup'lara dayalı derin özelleştirme",
  t52: "Büyük ve ultra büyük işletmeler için tam kapsamlı özelleştirme",
  t53: "Kurumsal Veri İşbirliği Gizlilik ve Güvenlik",
  t54: "ENIAC, zincir üzerindeki veri etkileşimlerini ve iş birliği verilerini gerçekleştirirken şirketler arasındaki gizli verilerin tamamen saklanmasını destekler.",
  t55: "Tedarik Zinciri Yönetimi",
  t56: "ENIAC ile kuruluşlar, ayrıntıları ifşa etmeden hızlı bir şekilde güvenilir bir tedarik zinciri blok zinciri yönetim sistemi oluşturabilir, sadece zincirdeki kritik bağlantı verilerinin sonuçlarını doğrulayabilir.",
  t57: "Yenilikçi Finans ve Ürünler",
  t58: "ENIAC, sıfır bilgi kanıtının zincir dışı bilgi işlem çözümüne dayalı olarak gizlilik korumalı kredi değerlendirmesi, sıfır bilgi varlık sertifikasyonu vb. gibi yeni finansal hizmetler geliştirmeleri için müşterileri desteklemektedir.",
  t59: "Akıllı Şehir ve IoT Güvenliği",
  t60: "Akıllı şehir ve IoT senaryolarındaki tüm bağlantı noktalarında cihaz verilerinin gizliliğini etkili bir şekilde korurken veri kullanılabilirliğini artırır.",
  t61: "Dijital Kimlik Uygulamaları",
  t62: "Kullanıcı bilgilerinin gizliliğinden ödün vermeden dijital kimliklerin kullanılabilirliğini artırın ve kullanıcılara daha fazla kolaylık sağlayın.",
  t63: "Sürdürülebilir Kaynak Yönetimi",
  t64: "İşletmeler veya kuruluşlar enerji gibi kritik kaynakları etkin bir şekilde izleyebilir ve verimli bir şekilde tahsis edebilirken, verilerin her bir halkası zincir boyunca doğrulanabilir.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "İş Portföyü",
  "t102-1": "我們的業務组合",
  t103: "Sağlık ve Tıbbi Araştırma",
  "t103-1":
    "ENIAC, hastaların ve kullanıcıların mahremiyetini koruyarak, araştırma ekibinin bu özel bilgilere erişimi olmadan etkili tıbbi araştırmalar yapılmasına olanak tanır.",
  t104: "Telif Hakkı ve İçerik Oluşturma Koruması",
  "t104-1":
    "ENIAC ile piyasa daha verimli bir telif hakkı koruma ve lisanslama mekanizması kurabilir, sağlıklı fikri mülkiyet haklarının gerçekleştirilmesini ve dolaşımını gerçekleştirebilir.",
  t105: "Tamamen özel çevrimiçi oylama ve araştırma pazarı.",
  "t105-1":
    "Sıfır bilgi kanıtı zincirleme hesaplamaya dayalı olarak, kullanıcılar araştırma verilerini ifşa etmeden veya bunlara dokunmadan daha derinlemesine analiz sonuçları elde edebilirler.",
};
