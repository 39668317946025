export default {
  home: "홈",
  about: "소개",
  document: "문서",
  t1: "引入去中心化通用計算的",
  t2: "엔터프라이즈 범용 컴퓨팅 블록체인",
  t3: "모듈화된 범용 컴퓨팅 코프로세서로 구성된 이더넷 최초의 탈중앙화 네트워크",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "엔터프라이즈급 애플리케이션의 컴퓨팅 요구사항 충족",
  t10: "더 빠르고, 더 강력하고, 더 안전한 분산형 범용 컴퓨팅 네트워크",
  t11: "개발자와 기업은 고성능 애플리케이션을 자유롭게 구축하고 배포할 수 있습니다.",
  t12: "더 빠른 속도",
  t13: "최대 300ms의 블록 유효성 검사 속도, 최대 20,000의 TPS",
  t14: "낮은 컴퓨팅 비용",
  t15: "탈중앙화된 대규모 오프체인 컴퓨팅 솔루션은 기업의 컴퓨팅 파워 비용을 효과적으로 절감합니다.",
  t16: "엔터프라이즈급 개인정보 보안",
  t17: "오프체인 컴퓨팅을 통해 기업의 개인정보와 민감한 계산이 보호됩니다.",
  t18: "일반 컴퓨팅 기능",
  t19: "이더넷은 고급 확장 솔루션을 통해 엔터프라이즈 애플리케이션의 컴퓨팅 요구 사항도 충족할 수 있습니다.",
  t20: "탈중앙화 및 투명성",
  t21: "이더넷 최초로 합의 계층을 도입한 분산형 레이어2 네트워크",
  t22: "호환성 및 확장성",
  t23: "Type1 이더넷 호환성은 물론 zkVM 기반 개발 언어의 모든 기능 및 고급 기능을 지원합니다.",
  t24: "ENIAC 핵심 아키텍처",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "ENIAC 아키텍처 기반의 핵심 애플리케이션 모듈",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "영지식 머신 러닝",
  t38: "영지식 증명과 머신 러닝을 결합하여 데이터 프라이버시와 신뢰성을 보호하면서 효율성을 개선합니다.",
  t39: "영지식 분산 메타버스",
  t40: "메타버스를 위한 신뢰할 수 있는 환경과 강력한 저비용 컴퓨팅 인프라를 제공합니다.",
  t41: "제로 지식 탈중앙화 신원",
  t42: "탈중앙화된 네트워크와 영지식 인증을 기반으로 사용자에게 신뢰할 수 있는 디지털 신원을 제공합니다.",
  t43: "서비스형 롤업",
  t44: "zkVM을 기반으로 엔터프라이즈 롤업의 신속한 배포를 지원합니다.",
  t45: "ENIAC 살펴보기",
  t46: "분산형 범용 컴퓨팅의 무한한 가능성 탐구",
  t47: "l 표준화된 엔터프라이즈급 블록체인 애플리케이션 솔루션",
  t48: "스타트업을 위한 온디맨드 방식의 신속한 배포",
  t49: "l 모듈화되고 신속하게 맞춤화된 엔터프라이즈 블록체인 제품 및 서비스",
  t50: "중소기업을 위한 유연한 옵션, 빠른 적응력",
  t51: "l 앱롤업에 기반한 심층적인 커스터마이징",
  t52: "대기업 및 초대형 기업의 경우, 심층적인 맞춤형 서비스 제공",
  t53: "엔터프라이즈 데이터 협업 개인정보 보호 및 보안",
  t54: "ENIAC은 체인에서 데이터 상호 작용 및 비즈니스 협력 데이터를 수행하면서 기업 간 기밀 데이터의 완전한 보존을 지원합니다.",
  t55: "공급망 관리",
  t56: "ENIAC을 통해 조직은 세부 정보를 공개하지 않고도 신뢰할 수 있는 공급망 블록체인 관리 시스템을 신속하게 구축하고, 체인상의 중요한 링크 데이터의 결과를 간단히 검증할 수 있습니다.",
  t57: "혁신적인 금융 및 제품",
  t58: "ENIAC은 영지식 증명의 오프체인 컴퓨팅 솔루션을 기반으로 개인 정보가 보호되는 신용 평가, 영지식 자산 인증 등 새로운 금융 서비스를 개발할 수 있도록 지원합니다.",
  t59: "스마트 시티 및 IoT 보안",
  t60: "스마트 시티 및 IoT 시나리오의 모든 포트에서 디바이스 데이터의 프라이버시를 효과적으로 보호하는 동시에 데이터 가용성을 개선합니다.",
  t61: "디지털 신원 애플리케이션",
  t62: "사용자 정보의 프라이버시를 유지하면서 디지털 ID의 사용성을 향상시켜 사용자에게 더 많은 편의를 제공합니다.",
  t63: "지속 가능한 리소스 관리",
  t64: "기업이나 조직은 에너지와 같은 중요 자원을 효과적으로 추적하고 효율적으로 할당할 수 있으며, 각 데이터 링크를 체인 상에서 확인할 수 있습니다.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "비즈니스 포트폴리오",
  "t102-1": "我們的業務组合",
  t103: "건강 및 의료 연구",
  "t103-1":
    "ENIAC은 환자와 사용자의 개인 정보를 보호하여 연구팀이 개인 정보에 액세스하지 않고도 효과적인 의료 연구를 수행할 수 있도록 합니다.",
  t104: "저작권 및 콘텐츠 제작 보호",
  "t104-1":
    "ENIAC을 통해 시장은 보다 효율적인 저작권 보호 및 라이선스 메커니즘을 구축하여 지적 재산권을 건전하게 관리 및 유통할 수 있습니다.",
  t105: "완전 비공개 온라인 투표 및 리서치 마켓플레이스.",
  "t105-1":
    "제로 지식 증명 다운 체인 컴퓨팅을 기반으로 사용자는 연구 데이터를 공개하거나 건드리지 않고도 보다 심층적인 분석 결과를 얻을 수 있습니다.",
};
