<script setup></script>
<template>
  <svg viewBox="0 0 248 248" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 644">
      <path
        id="Rectangle 139"
        d="M120.673 71.5208C122.649 70.6609 124.893 70.6623 126.868 71.5246L235.486 118.96C240.861 121.307 241.781 128.544 237.165 132.162L135.991 211.454C128.82 217.074 118.735 217.06 111.581 211.419L10.6589 131.854C6.0572 128.226 6.9912 121 12.3642 118.662L120.673 71.5208Z"
        fill="url(#paint0_linear_153_344)"
        stroke="url(#paint1_linear_153_344)"
        stroke-width="0.5"
      />
      <path
        id="Rectangle 140"
        d="M120.673 71.5208C122.649 70.6609 124.893 70.6623 126.868 71.5246L235.486 118.96C240.861 121.307 241.781 128.544 237.165 132.162L135.991 211.454C128.82 217.074 118.735 217.06 111.581 211.419L10.6589 131.854C6.0572 128.226 6.9912 121 12.3642 118.662L120.673 71.5208Z"
        fill="url(#paint2_linear_153_344)"
        stroke="url(#paint3_linear_153_344)"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_344"
        x1="123.989"
        y1="99.4405"
        x2="144.533"
        y2="224.491"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#61C3D8" />
        <stop offset="1" stop-color="#00FF47" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_153_344"
        x1="123.97"
        y1="74.8924"
        x2="123.97"
        y2="240.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_153_344"
        x1="123.989"
        y1="162.267"
        x2="125.819"
        y2="212.537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003396" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_153_344"
        x1="123.97"
        y1="74.8924"
        x2="123.97"
        y2="240.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 2.48rem;
  height: 2.48rem;
}
</style>
