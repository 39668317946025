<script setup></script>
<template>
  <svg viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Vector" filter="url(#filter0_d_153_163)">
      <path
        d="M10.3227 22.9136V22.7864H8.67273C8.13258 22.7864 7.65 22.3291 7.65 21.7636V2.67273C7.65 2.05672 8.05672 1.65 8.67273 1.65H27.8909C28.4311 1.65 28.9136 2.1073 28.9136 2.67273V21.8909C28.9136 22.4311 28.4563 22.9136 27.8909 22.9136H10.3227ZM39.7591 22.9136V22.7864H38.1091C37.5689 22.7864 37.0864 22.3291 37.0864 21.7636V2.67273C37.0864 2.13258 37.5437 1.65 38.1091 1.65H57.3273C57.9433 1.65 58.35 2.05673 58.35 2.67273V21.8909C58.35 22.4311 57.8927 22.9136 57.3273 22.9136H39.7591ZM8.67273 52.35C8.05673 52.35 7.65 51.9433 7.65 51.3273V32.1091C7.65 31.5689 8.10731 31.0864 8.67273 31.0864H27.8909C28.4311 31.0864 28.9136 31.5437 28.9136 32.1091V51.3273C28.9136 51.8674 28.4563 52.35 27.8909 52.35H8.67273ZM38.1091 52.35C37.5689 52.35 37.0864 51.8927 37.0864 51.3273V32.1091C37.0864 31.5689 37.5437 31.0864 38.1091 31.0864H57.3273C57.8674 31.0864 58.35 31.5437 58.35 32.1091V51.3273C58.35 51.8674 57.8927 52.35 57.3273 52.35H38.1091Z"
        stroke="white"
        stroke-width="3.3"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_163"
        x="0"
        y="0"
        width="74"
        height="74"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="10" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00541523 0 0 0 0 0.0342157 0 0 0 0 0.0433105 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_163"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_163"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 21px;
  height: 21px;
}
</style>
