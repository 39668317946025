export default {
  home: "Startseite",
  about: "Über",
  document: "Dokument",
  t1: "引入去中心化通用計算的",
  t2: "Enterprise General Purpose Computing Blockchain",
  t3: "Das erste dezentralisierte Netzwerk von modularisierten Universalcomputer-Koprozessoren im Ethernet",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "Erfüllt die Rechenanforderungen von Anwendungen der Unternehmensklasse",
  t10: "Ein schnelleres, stärkeres und sichereres dezentralisiertes General-Purpose-Computing-Netzwerk",
  t11: "Entwickler und Unternehmen können hochleistungsfähige Anwendungen erstellen und einsetzen.",
  t12: "Schneller als schnell",
  t13: "Bis zu 300 ms Blockvalidierungsgeschwindigkeit, TPS bis zu 20.000",
  t14: "Niedrige Computing-Kosten",
  t15: "Die dezentralisierte, groß angelegte Off-Chain-Computing-Lösung senkt die Kosten der Rechenleistung für Unternehmen effektiv.",
  t16: "Datenschutz auf Unternehmensniveau",
  t17: "Die Privatsphäre von Unternehmen und sensible Berechnungen werden durch Off-Chain-Computing geschützt.",
  t18: "Allgemeine Computing-Fähigkeit",
  t19: "Mit fortschrittlicheren Skalierungslösungen kann Ethernet auch die Rechenanforderungen von Unternehmensanwendungen erfüllen",
  t20: "Dezentralisiert und transparent",
  t21: "Das erste dezentralisierte Layer2-Netzwerk im Ethernet, das eine Konsensschicht einführt",
  t22: "Kompatibilität und Erweiterbarkeit",
  t23: "Unterstützt Typ1-Ethernet-Kompatibilität sowie alle Funktionen und erweiterten Eigenschaften der auf zkVM basierenden Entwicklungssprache.",
  t24: "ENIAC-Kernarchitektur",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "Kernanwendungsmodule auf Basis der ENIAC-Architektur",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "Maschinelles Lernen ohne Wissen ",
  t38: "Zero-Knowledge-Proofing kombiniert mit maschinellem Lernen zur Verbesserung der Effizienz bei gleichzeitigem Schutz der Privatsphäre und der Vertrauenswürdigkeit der Daten.",
  t39: "Null Wissen Dezentrales Metaversum",
  t40: "Bietet eine vertrauenswürdige Umgebung und eine leistungsstarke, kostengünstige Recheninfrastruktur für das Metaverse",
  t41: "Dezentralisierte Identität ohne Wissen",
  t42: "Bietet Nutzern eine vertrauenswürdige digitale Identität, die auf einem dezentralen Netzwerk und Zero-Knowledge-Authentifizierung basiert.",
  t43: "Rollup als Dienstleistung ",
  t44: "Unterstützt die schnelle Bereitstellung von Unternehmens-Rollup auf der Basis von zkVM.",
  t45: "ENIAC erforschen",
  t46: "Erforschung der unbegrenzten Möglichkeiten des dezentralen General Purpose Computing",
  t47: "l Standardisierte Blockchain-Anwendungslösungen in Unternehmensqualität",
  t48: "Schnelle Bereitstellung, On-Demand für Startups",
  t49: "l Modularisierte und schnell anpassbare Blockchain-Produkte und -Dienstleistungen für Unternehmen",
  t50: "Für KMUs, flexible Optionen, schnelle Anpassung",
  t51: "l Tiefgreifende Anpassung auf Basis von AppRollups",
  t52: "Für große und sehr große Unternehmen, umfassende und tiefgreifende Anpassungsmöglichkeiten",
  t53: "Datenschutz und Sicherheit bei der Zusammenarbeit mit Unternehmensdaten",
  t54: "ENIAC unterstützt die vollständige Aufbewahrung vertraulicher Daten zwischen Unternehmen bei der Durchführung von Dateninteraktionen und Geschäftskooperationen in der Datenkette.",
  t55: "Management der Lieferkette",
  t56: "Mit ENIAC können Unternehmen schnell ein vertrauenswürdiges Supply-Chain-Blockchain-Management-System aufbauen, ohne Details preiszugeben, und einfach die Ergebnisse kritischer Verbindungsdaten in der Kette validieren.",
  t57: "Innovative Finanzen und Produkte",
  t58: "ENIAC unterstützt Kunden bei der Entwicklung neuer Finanzdienstleistungen, wie z. B. datenschutzfreundliche Kreditwürdigkeitsprüfung, Zertifizierung von Vermögenswerten mit Null-Wissen usw., die auf der Off-Chain-Computing-Lösung des Zero-Knowledge-Proofs basieren.",
  t59: "Smart City und IoT-Sicherheit",
  t60: "Schützt effektiv die Privatsphäre von Gerätedaten über alle Ports in Smart City- und IoT-Szenarien und verbessert gleichzeitig die Datenverfügbarkeit.",
  t61: "Anwendungen für digitale Identitäten",
  t62: "Verbessert die Benutzerfreundlichkeit digitaler Identitäten, ohne die Privatsphäre der Benutzerdaten zu beeinträchtigen, und bietet den Benutzern mehr Komfort.",
  t63: "Nachhaltiges Ressourcenmanagement",
  t64: "Unternehmen oder Organisationen können kritische Ressourcen wie Energie effektiv nachverfolgen und effizient zuweisen, wobei jedes Datenglied in der Kette überprüft werden kann.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "Geschäftsportfolio",
  "t102-1": "我們的業務组合",
  t103: "Gesundheit und medizinische Forschung",
  "t103-1":
    "ENIAC schützt die Privatsphäre von Patienten und Nutzern und ermöglicht so die Durchführung effektiver medizinischer Forschung, ohne dass das Forschungsteam Zugang zu diesen privaten Informationen hat.",
  t104: "Schutz des Urheberrechts und der Erstellung von Inhalten",
  "t104-1":
    "Mit ENIAC kann der Markt einen effizienteren Mechanismus zum Schutz von Urheberrechten und zur Lizenzierung einrichten, der eine gesunde Verwertung und Verbreitung von Rechten an geistigem Eigentum ermöglicht.",
  t105: "Völlig privater Online-Abstimmungs- und Forschungsmarktplatz.",
  "t105-1":
    "Auf der Grundlage des Zero-Proof-of-Knowledge-Down-Chain-Computings können die Nutzer tiefer gehende Analyseergebnisse erhalten, ohne die Forschungsdaten offenzulegen oder zu berühren.",
};
