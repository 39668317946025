<script setup></script>
<template>
  <svg viewBox="0 0 160 162" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_153_537)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62 80.626V61H85.0009V80.626H62ZM60 82.626V80.626V61V59H62H85.0009H87.0009V61V80.626V82.626H85.0009H62H60ZM99.7764 63V72.4504H90.326V63H99.7764ZM98.1073 64.6691H91.9951V70.7813H98.1073V64.6691ZM73.4506 91.3512V100.802H64.0003V91.3512H73.4506ZM71.7807 93.0203H65.6685V99.1325H71.7807V93.0203ZM65.0808 67.8349V68.7178C65.0874 69.3337 65.2983 69.9123 65.6614 70.3842L65.67 70.3955L64.1698 71.1696C63.3737 71.5794 62.9132 72.4229 63.014 73.2985C63.1188 74.2114 63.9109 74.8961 64.8459 74.8961H71.1543C72.0899 74.8961 72.8814 74.2114 72.9868 73.2985L72.9895 73.2724C73.0782 72.4062 72.6191 71.5754 71.8303 71.169L70.3301 70.3955L70.3395 70.3842C70.7154 69.8983 70.9196 69.3014 70.92 68.6871V67.8349C70.92 66.2646 69.6087 65 68.0004 65C66.3921 65 65.0808 66.2646 65.0808 67.8349ZM76.4215 71.6257V73.7354H82.8166V71.6257H76.4215ZM75.0003 66V68.1089H82.8166V66H75.0003ZM94.1791 72.9683L94.1683 75.4719L95.8374 75.4786L95.8482 72.9749L94.1791 72.9683ZM94.1583 77.9755L94.1483 80.4792L95.8174 80.4858L95.8274 77.9822L94.1583 77.9755ZM94.1383 82.9828L94.1274 85.4864L95.7965 85.4931L95.8073 82.9895L94.1383 82.9828ZM94.1174 87.9901L94.1074 90.4937L95.7765 90.5004L95.7865 87.9967L94.1174 87.9901ZM88.5184 95.1676H86.0148V96.8367H88.5184V95.1676ZM83.5112 95.1676H81.0075V96.8367H83.5112V95.1676ZM78.5039 95.1676H76.0003V96.8367H78.5039V95.1676ZM99.9995 91.2769H90.5491V100.727H99.9995V91.2769ZM89.9967 67.147V68.8161H87.3462V67.147H89.9967ZM69.5229 82.7846V91.0284H67.8538V82.7846H69.5229Z"
        fill="#55FF99"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_537"
        x="0"
        y="0"
        width="160"
        height="161.802"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="30" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0.678431 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_537"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_537"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 16px;
  height: 16px;
  transform: scale(6.5);
}
</style>
