<script setup></script>
<template>
  <svg viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_153_514)">
      <path
        d="M99.0024 85.2335C97.4679 89.9086 94.267 93.8515 90.0129 96.3068C85.7627 98.7762 80.7528 99.5848 75.9445 98.5777C80.0471 97.9625 84.4351 96.4486 88.6791 93.9905C92.8111 91.5993 96.2415 88.6411 98.805 85.4823L99.005 85.2335H99.0024ZM99.3971 73.9596C99.7391 75.2991 99.9412 76.6706 100 78.0519C96.9244 82.4304 92.2269 86.6886 86.4117 90.0534C80.5966 93.4208 74.568 95.3734 69.249 95.8494C68.0841 95.1071 66.9996 94.2447 66.0134 93.2764C71.4604 93.4529 78.3639 91.6234 85.078 87.7371C91.4347 84.0567 96.2842 79.3064 98.9677 74.7219L99.3945 73.9596H99.3971ZM96.5216 67.6045C97.082 68.4279 97.5813 69.2915 98.0154 70.1883C94.8224 74.8503 89.4207 79.6648 82.6772 83.5672C75.9365 87.4723 69.081 89.7565 63.4552 90.2005C62.8963 89.3734 62.3988 88.5063 61.9668 87.606C67.2271 87.4642 74.3013 85.3272 81.3435 81.2509C88.0229 77.3833 93.2085 72.6196 96.0734 68.3053L96.5216 67.6045ZM89.3673 61.2013C90.261 61.6747 91.1226 62.2177 91.9441 62.8275C91.1812 67.7008 86.2837 73.4488 79.2095 77.5438C72.1353 81.6414 64.717 83.0189 60.1289 81.2429C60.0146 80.2308 59.9762 79.2115 60.0142 78.1937C63.2098 80.3789 70.6121 79.4348 77.8757 75.2275C84.8486 71.1913 89.2927 65.5423 89.3807 61.6747L89.3673 61.2013ZM82.2504 59C83.1681 59.1043 84.075 59.2701 84.966 59.5002C84.99 63.3277 81.2288 68.0378 75.6084 71.2903C69.9906 74.5427 64.0421 75.4575 60.745 73.5263C60.9931 72.633 61.3052 71.761 61.6707 70.9105C63.5966 72.6143 68.9636 72.0472 74.2746 68.974C79.5029 65.9489 82.6586 61.6587 82.2745 59.1204L82.2478 59H82.2504ZM76.7821 59.9549C77.3342 60.9151 75.1976 63.1913 72.0072 65.0368C68.8169 66.8824 65.784 67.6019 65.2318 66.6416C64.9064 66.0746 65.5172 65.0475 66.7336 63.9215C67.743 63.0271 68.8395 62.2368 70.0066 61.5624L70.6762 61.1879C71.6352 60.6812 72.6334 60.2528 73.6611 59.9067C75.2509 59.4092 76.454 59.3852 76.7821 59.9549Z"
        fill="#55FF99"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_514"
        x="0"
        y="0"
        width="160"
        height="160"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="30" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0.678431 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_514"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_514"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 16px;
  height: 16px;
  transform: scale(6.5);
}
</style>
