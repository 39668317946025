<script setup></script>
<template>
  <svg viewBox="0 0 124 201" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 643">
      <path
        id="Vector"
        d="M3.80838 115.722L61.512 151.86V198.608L3.80838 115.722Z"
        fill="url(#paint0_linear_153_351)"
        stroke="url(#paint1_linear_153_351)"
      />
      <path
        id="Vector_2"
        d="M62.3203 198.394V151.672L119.471 115.543L62.3203 198.394Z"
        fill="url(#paint2_linear_153_351)"
        stroke="url(#paint3_linear_153_351)"
      />
      <path
        id="Vector 9"
        d="M61.5397 88.5286L120.206 113.898L61.5384 151.159L3.06223 114.081L61.5397 88.5286Z"
        fill="url(#paint4_linear_153_351)"
        stroke="white"
      />
      <path
        id="Vector_3"
        d="M61.5 131.162L0.707888 98.3737L61.5 1.73386V131.162Z"
        fill="url(#paint5_linear_153_351)"
        stroke="url(#paint6_linear_153_351)"
      />
      <path
        id="Vector_4"
        d="M123.292 98.3737L62.5 131.162V1.73386L123.292 98.3737Z"
        fill="url(#paint7_linear_153_351)"
        stroke="url(#paint8_linear_153_351)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_351"
        x1="16.5"
        y1="200"
        x2="36.4698"
        y2="135.892"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00342476" />
        <stop offset="0.643872" stop-color="#C6A5F0" />
        <stop offset="0.908074" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_153_351"
        x1="32"
        y1="172.5"
        x2="62"
        y2="151.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_153_351"
        x1="86.5"
        y1="130.5"
        x2="88"
        y2="190"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00BD85" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_153_351"
        x1="62"
        y1="203"
        x2="62"
        y2="152"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_153_351"
        x1="30.5"
        y1="139.5"
        x2="41.3407"
        y2="97.8917"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00342476" stop-color="#8ED6FF" />
        <stop offset="0.643872" stop-color="#ABFFFA" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_153_351"
        x1="1.74401"
        y1="100.247"
        x2="75.7995"
        y2="119.453"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.065786" />
        <stop offset="0.507414" stop-color="#42D751" />
        <stop offset="0.674735" stop-color="#1AFFBA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_153_351"
        x1="62"
        y1="0"
        x2="35"
        y2="117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_153_351"
        x1="62"
        y1="126"
        x2="81.82"
        y2="5.05243"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0919262" stop-color="#74FFD5" />
        <stop offset="0.424668" stop-color="#7473B9" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_153_351"
        x1="62"
        y1="-4"
        x2="90"
        y2="112.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 1.24rem;
  height: 2rem;
}
</style>
