import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/main.css";
import "./style/index.less";
import "./utils/rem";
import "animate.css";
import i18n from "@/language";

createApp(App).use(store).use(router).use(i18n).mount("#app");
