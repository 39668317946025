<script setup></script>
<template>
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 518">
      <rect
        id="Rectangle 58"
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        fill="black"
        stroke="#42FFAD"
      />
      <g id="Clip path group">
        <mask
          id="mask0_153_152"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="7"
          y="7"
          width="16"
          height="17"
        >
          <g id="clippath">
            <path id="Vector" d="M23 7H7V23.0172H23V7Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask0_153_152)">
          <g id="Group">
            <path
              id="Vector_2"
              d="M20.654 10.5862H19.4096V9.20093C19.4096 7.99093 18.3655 7.01025 17.0781 7.01025H9.32943C8.04215 7.01045 6.99805 7.99093 6.99805 9.20093V17.2314C6.99805 18.4414 8.04215 19.4217 9.32943 19.4217H10.5738V20.8069C10.5738 22.0169 11.6177 22.9976 12.9053 22.9976H20.654C21.9413 22.9976 22.9854 22.0169 22.9854 20.8069V12.7765C22.9854 11.567 21.9413 10.5862 20.654 10.5862ZM10.574 12.7768V18.1683H9.49902C8.87158 18.1683 8.363 17.7329 8.363 17.1964V9.45756C8.363 8.92126 8.87158 8.48586 9.49902 8.48586H16.9091C17.5363 8.48586 18.0449 8.92087 18.0449 9.45756V10.5864H12.9055C11.6179 10.5864 10.574 11.5672 10.574 12.777V12.7768ZM21.0808 14.89L19.4094 16.6457L16.7672 19.4215L16.5433 19.6566C16.4167 19.7889 16.252 19.8555 16.0847 19.8555C15.9174 19.8555 15.7549 19.7893 15.627 19.6566L15.4032 19.4215L14.2102 18.1681L13.0345 16.933C12.7828 16.6662 12.7828 16.2355 13.0345 15.9708C13.289 15.7044 13.6993 15.7044 13.951 15.9708L16.0431 18.1681L16.0851 18.212L16.127 18.1681L18.0449 16.1543L19.4098 14.7214L20.1649 13.9287C20.283 13.8008 20.4491 13.7281 20.623 13.7281C20.7968 13.7281 20.9631 13.8008 21.0812 13.9287C21.3343 14.1935 21.3343 14.6248 21.081 14.8898L21.0808 14.89Z"
              fill="#42FFAD"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 12px;
  height: 12px;
}
</style>
