<script setup></script>
<template>
  <svg viewBox="0 0 327 327" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 645">
      <path
        id="Rectangle 141"
        d="M160.622 94.458C162.598 93.5981 164.842 93.5995 166.817 94.4618L314.622 159.011C319.997 161.359 320.918 168.595 316.301 172.213L175.94 282.218C168.769 287.838 158.684 287.823 151.53 282.182L11.5229 171.803C6.92113 168.175 7.85514 160.949 13.2281 158.611L160.622 94.458Z"
        fill="url(#paint0_linear_153_347)"
        fill-opacity="0.9"
        stroke="url(#paint1_linear_153_347)"
        stroke-width="0.5"
      />
      <path
        id="Rectangle 142"
        d="M160.622 94.458C162.598 93.5981 164.842 93.5995 166.817 94.4618L314.622 159.011C319.997 161.359 320.918 168.595 316.301 172.213L175.94 282.218C168.769 287.838 158.684 287.823 151.53 282.182L11.5229 171.803C6.92113 168.175 7.85514 160.949 13.2281 158.611L160.622 94.458Z"
        fill="url(#paint2_linear_153_347)"
        stroke="url(#paint3_linear_153_347)"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_347"
        x1="156.5"
        y1="101.5"
        x2="199.924"
        y2="278.356"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05271" stop-color="#005F5F" />
        <stop offset="0.485457" stop-color="#6C5CD1" />
        <stop offset="1" stop-color="#30CDFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_153_347"
        x1="163.982"
        y1="99.4063"
        x2="163.982"
        y2="317.842"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_153_347"
        x1="164.007"
        y1="214.373"
        x2="166.415"
        y2="280.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003396" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_153_347"
        x1="163.982"
        y1="99.4063"
        x2="163.982"
        y2="317.842"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
}
</style>
