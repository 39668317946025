export default {
  home: "Главная",
  about: "О нас",
  document: "Документ",
  t1: "引入去中心化通用計算的",
  t2: "блокчейн корпоративных вычислений общего назначения",
  t3: "Первая децентрализованная сеть модульных вычислительных сопроцессоров общего назначения Ethernet",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "Удовлетворение вычислительных потребностей приложений корпоративного класса",
  t10: "Более быстрая, мощная и безопасная децентрализованная вычислительная сеть общего назначения",
  t11: "Разработчики и предприятия могут свободно создавать и развертывать высокопроизводительные приложения.",
  t12: "быстрее, чем быстро",
  t13: "Скорость подтверждения блоков до 300 мс, TPS до 20 000",
  t14: "Низкие затраты на вычисления",
  t15: "Децентрализованное крупномасштабное вычислительное решение off-chain эффективно снижает стоимость вычислительных мощностей для предприятий.",
  t16: "Безопасность конфиденциальности корпоративного уровня",
  t17: "Конфиденциальность корпоративной информации и конфиденциальные вычисления защищены вычислениями вне цепи.",
  t18: "Общие вычислительные возможности",
  t19: "Благодаря более совершенным решениям по масштабированию Ethernet также может удовлетворять вычислительные потребности корпоративных приложений",
  t20: "Децентрализованная и прозрачная",
  t21: "Первая децентрализованная сеть Layer2 в Ethernet, в которой появился уровень консенсуса",
  t22: "Совместимость и расширяемость",
  t23: "Поддерживает совместимость с Type1 Ethernet, а также все функциональные и расширенные возможности языка разработки на базе zkVM.",
  t24: "Архитектура ядра ENIAC",
  t25: "AppRollups",
  t26: "零知识证明层",
  t27: "去中心化计算网络",
  t28: "ENIAC去中心化共识层",
  t29: "DPoR机制节点 自适应协同",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "结算层",
  "t31-1": "以太坊网路",
  t32: "分布式验证网络",
  t33: "无需许可验节点钜阵",
  "t33-1": "RPoW机制",
  t34: "基於",
  t35: "Основные модули приложения, основанные на архитектуре ENIAC",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "Машинное обучение с нулевым знанием ",
  t38: "Доказательство нулевого знания в сочетании с машинным обучением повышает эффективность, защищая конфиденциальность и достоверность данных.",
  t39: "Децентрализованная метаверсия Zero Knowledge",
  t40: "Обеспечивает доверенную среду и мощную недорогую вычислительную инфраструктуру для метавселенной.",
  t41: "Децентрализованная идентификация с нулевым знанием",
  t42: "Предоставляет пользователям надежную цифровую идентификацию, основанную на децентрализованной сети и аутентификации с нулевым знанием.",
  t43: "Rollup as a Service ",
  t44: "Поддержка быстрого развертывания корпоративной системы Rollup на базе zkVM.",
  t45: "Изучите ENIAC",
  t46: "Исследование неограниченных возможностей децентрализованных вычислений общего назначения",
  t47: "l Стандартизированные решения для приложений блокчейн корпоративного уровня",
  t48: "Быстрое развертывание по требованию для стартапов",
  t49: "l Модулированные и быстро настраиваемые корпоративные блокчейн-продукты и услуги.",
  t50: "Для малых и средних предприятий - гибкие возможности, быстрая адаптация",
  t51: "l Глубокая настройка на основе AppRollups",
  t52: "Для крупных и сверхкрупных предприятий - полный спектр глубокой настройки",
  t53: "Конфиденциальность и безопасность совместной работы с корпоративными данными",
  t54: "ENIAC поддерживает полную сохранность конфиденциальных данных между компаниями, обеспечивая при этом взаимодействие данных и деловое сотрудничество по цепочке.",
  t55: "Управление цепочкой поставок",
  t56: "С помощью ENIAC организации могут быстро создать надежную систему управления цепочками поставок на основе блокчейна, не раскрывая деталей, а просто подтверждая результаты критически важных данных о связях в цепочке.",
  t57: "Инновационные финансы и продукты",
  t58: "ENIAC поддерживает клиентов в разработке новых финансовых услуг, таких как оценка кредитов с защитой конфиденциальности, сертификация активов с нулевым знанием и т.д., основанных на вычислительном решении вне цепочки с доказательством нулевого знания.",
  t59: "Безопасность умного города и IoT",
  t60: "Эффективная защита конфиденциальности данных устройств через все порты в сценариях умного города и IoT при одновременном повышении доступности данных.",
  t61: "Приложения для цифровой идентификации",
  t62: "Повышение удобства использования цифровых идентификаторов без ущерба для конфиденциальности пользовательской информации, что повышает удобство для пользователей.",
  t63: "Устойчивое управление ресурсами",
  t64: "Предприятия или организации могут эффективно отслеживать и рационально распределять критически важные ресурсы, такие как энергия, при этом каждое звено данных может быть проверено по всей цепочке.",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是Eniac？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。Eniac是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是Eniac，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，Eniac允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "Eniac有哪些优势？",
  t98: "Eniac是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20000，因此Eniac可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，Eniac引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外Eniac具有高度的兼容性和开放性，Eniac集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而Eniac zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "Eniac填补了什么市场空白",
  t101: "Eniac填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上Eniac填补了Web2与Web3之间的空白。",
  t102: "Бизнес-портфолио",
  "t102-1": "我們的業務组合",
  t103: "Здравоохранение и медицинские исследования",
  "t103-1":
    "ENIAC защищает конфиденциальность пациентов и пользователей, позволяя проводить эффективные медицинские исследования без доступа исследовательской группы к этой частной информации.",
  t104: "Защита авторских прав и создание контента",
  "t104-1":
    "С помощью ENIAC рынок может создать более эффективный механизм защиты авторских прав и лицензирования, обеспечивая здоровый процесс реализации и обращения прав интеллектуальной собственности.",
  t105: "Полностью приватный рынок онлайн-голосований и исследований.",
  "t105-1":
    "Основываясь на вычислениях с нулевым доказательством знания, пользователи могут получать более глубокие результаты анализа, не раскрывая и не касаясь данных исследования.",
};
