export default {
  home: "主页",
  about: "关于",
  document: "白皮书",
  t1: "引入去中心化通用計算的",
  t2: "企業級通用計算區塊鏈",
  t3: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t4: "Ethereum",
  t5: "De Generalcomputing",
  t6: "De Zk-Rollup",
  t7: "300ms",
  t8: "20,000",
  t9: "滿足企業級應用的計算需求",
  t10: "更快更強更安全的去中心化通用計算網路，",
  t11: "開發者和企業可以自由構建和部署高性能應用",
  t12: "比快更快",
  t13: "最高300ms區塊確認速度，TPS最高20,000",
  t14: "低計算成本",
  t15: "去中心化的大規模鏈下計算方案有效降低企業算力成本",
  t16: "企業級隱私安全",
  t17: "基於鏈下計算，企業隱私資料和敏感計算可得到有效保護",
  t18: "通用計算能力",
  t19: "基於更先進的擴容方案，以太坊也可滿足企業級應用計算需求",
  t20: "去中心化且透明",
  t21: "以太坊中首個引入共識層的去中心化Layer2網路",
  t22: "相容性和拓展性",
  t23: "支援Type1以太坊相容，同時基於zkVM支援開發語言所有功能及高級特性",
  t24: "核心架構",
  t25: "AppRollups",
  t26: "ZK执行层",
  t27: "分布式计算网络",
  t28: "共识层",
  t29: "DPoR机制",
  "t29-1": "节点自适应协同",
  t30: "验证处理",
  t31: "分布式验证网络",
  "t31-1": "RPoW机制",
  t32: "结算层",
  t33: "以太坊网络",
  "t33-1": "",
  t34: "基於",
  t35: "構建的核心應用模組",
  t36: "未來，ENIAC將率先向這些領域提供成套企業級解決方案",
  t37: "零知識機器學習",
  t38: "零知識證明結合機器學習，提升效率同時保護資料隱私和可信度",
  t39: "零知識分散式元宇宙",
  t40: "為元宇宙提供可信環境和強大低成本算力基礎設施",
  t41: "零知識去中心化身份",
  t42: "為使用者提供基於去中心化網路以及零知識驗證的可信數位身份",
  t43: "Rollup即服務",
  t44: "支持基於zkVM的企業級Rollup快速部署",
  t45: "在這裡發現",
  t46: "探索去中心化通用計算的無限可能",
  t47: "標準化企業級區塊鏈應用解決方案",
  t48: "面向初創企業，快速部署，按需使用",
  t49: "模組化快速定制企業級區塊鏈產品服務",
  t50: "面向中小企業，靈活選擇，快速調整",
  t51: "基於AppRollups的深度定制",
  t52: "面向大型及超大型企業，全方位深度定制",
  t53: "企業資料協作隱私安全",
  t54: "ENIAC支援企業間完全保留企業機密資料同時在鏈上完成資料交互和商業合作資料",
  t55: "供應鏈管理",
  t56: "借助ENIAC，企業可快速構建可信的供應鏈區塊鏈管理系統，無需透露細節，只需在鏈上驗證各關鍵環節資料結果",
  t57: "創新金融和產品",
  t58: "基於零知識證明的鏈下計算方案，ENIAC支援客戶開發新型金融服務，如隱私保護下的信貸評估、零知識資產證明等",
  t59: "智慧城市和物聯網安全",
  t60: "有效保護智慧城市和IoT場景中全埠設備資料隱私安全，同時提升資料可用性",
  t61: "數位身份應用",
  t62: "在不犧牲使用者隱私資訊的前提下提升數位身份的可用性，為用戶帶來更多便利",
  t63: "資源可持續管理",
  t64: "企業或組織可以對能源等重要資源進行有效溯源和高效分配，同時每個環節資料可以進行鏈上驗證",
  t65: "以太坊首個模組化通用計算輔助處理器的分散式網路",
  t66: "关于我们",
  t67: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t68: "ENIAC社交账号和社区",
  t69: "滿足",
  t70: "企業級應用",
  t71: "的計算需求",
  t72: "ZK应用层",
  t73: "ZK执行层",
  t74: "分布式计算网络",
  t75: "共识层",
  t76: "DPoR机制",
  t77: "节点自适应协同",
  t78: "结算层",
  t79: "以太坊网络",
  t80: "明天的ENIAC",
  t81: "我們的業務组合",
  t82: "健康及醫療研究",
  t83: "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t84: "版權和內容創作保護",
  t85: "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t86: "完全隱私的線上投票和調研市場",
  t87: "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
  t88: "隱私政策",
  t89: "公司介绍",
  t90: "ENIAC是一个企业级区块链，是首个模块化通用计算协处理器分布式网络，愿景是基于系模块化所带来的综合性能，在传统区块链业务的基础上，打造面向企业的标准化产品、定制化产品、深度定制产品三层业务方案，帮助以太坊快速拓展企业级用户，打造以太坊实用价值，拓展用户规模。",
  t91: "联系我们",
  t92: "ENIAC社交账号和社区",
  t93: "常见问题",
  t94: "为什么是ENIAC？灵感来源是什么？",
  t95: "所有的开始都来自于“以太坊的世界计算机”，这是Vitalik Buterin在2014年首次提出的设想，他希望建立一个全球化的计算基础设施，具有分布式、公开透明、永不下线的特性。ENIAC是这个理想的眼神。",
  t96: "我们认为以太坊或者现有的公链不应该仅仅只是简单的智能合约平台，处理特定的任务，越来越多的企业和开发者拥抱区块链，因此市场需要一个对复杂场景更有宽容度的平台。这就是ENIAC，它作为一种全新的扩容方案，为以太坊等L1引入了通用计算能力，从Web3到Web2，ENIAC允许任何人快速建立区块链解决方案，包括现有业务直接移植等。而ENIAC则是为了纪念世界上第一台通用计算机（Electronic Numerical Integrator And Computer）",
  t97: "ENIAC有哪些优势？",
  t98: "ENIAC是当前市场上极少数兼顾去中心化和通用计算能力的扩容方案。首先得益于zk-Rollup和模块化，链下计算可以承担更加复杂的计算任务，理论上区块确认速度可以达到300ms，TPS最高20,000，因此ENIAC可以满足跨多场景下的计算需求，而不仅仅是处理交易类智能合约。此外，ENIAC引入了全新的DPoR去中心化共识共识机制，在保证网络效率同时，确保平台公开透明。",
  t99: "此外ENIAC具有高度的兼容性和开放性，ENIAC集成了zkEVM和zkVM两种主流虚拟机，其中zkEVM兼容当前所有的主流EVM。而ENIAC zkVM采用RISC指令集，支持Rust、C/C++、Java等多语言开发，现有业务也可以快速移植。",
  t100: "ENIAC填补了什么市场空白",
  t101: "ENIAC填补了去中心化和区块链领域中，对于去中心化通用计算板块的空白。即当前互联网在寻求建立区块链和去中心化应用时无法找到适合的平台，事实上ENIAC填补了Web2与Web3之间的空白。",
  t102: "明天的ENIAC：",
  "t102-1": "我們的業務组合",
  t103: "健康及醫療研究",
  "t103-1":
    "ENIAC可以很好地保護病患及用戶健康隱私，在研究團隊不接觸這些隱私資料同時仍然可以進行有效的醫學研究",
  t104: "版權和內容創作保護",
  "t104-1":
    "借助ENIAC，市場可以建立更加高效的版權保護及授權機制，實現健康的智慧財產權變現和流通",
  t105: "完全隱私的線上投票和調研市場",
  "t105-1":
    "基於零知識證明鏈下計算，使用者調研資料無需公開或觸及即可獲得更加深入的分析結果",
};
