<script setup></script>
<template>
  <svg viewBox="0 0 154 162" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_153_510)">
      <path
        d="M87.0149 79.9839L84.6132 83.1239C84.2129 83.6399 83.4034 83.6221 83.0387 83.0795L82.8163 82.7503C82.3449 82.0565 82.0869 81.2381 82.0869 80.402V63.1185C82.0869 62.14 81.2686 61.3395 80.2545 61.3395C79.2404 61.3395 78.4221 62.1311 78.4221 63.1185V78.0003C78.4221 78.6852 77.8439 79.2456 77.1412 79.2456H76.8832C76.1716 79.2456 75.6023 78.6852 75.6023 78.0003V60.7791C75.6023 59.8006 74.7839 59 73.7699 59C72.7558 59 71.9374 59.7917 71.9374 60.7791V77.9914C71.9374 78.6763 71.3592 79.2367 70.6565 79.2367C69.9449 79.2367 69.3756 78.6763 69.3756 77.9914V62.1578C69.3756 61.1793 68.5572 60.3788 67.5432 60.3788C66.5291 60.3788 65.7108 61.1704 65.7108 62.1578V79.9038C65.7108 80.5888 65.1326 81.1492 64.4298 81.1492H64.3231C63.6115 81.1492 63.0422 80.5888 63.0422 79.9038V68.8026C63.0422 67.9931 62.3661 67.326 61.5211 67.326C60.6849 67.326 60 67.9842 60 68.8026V88.4255C60 94.9991 65.5328 100.808 72.311 100.994C78.7067 101.164 83.6525 97.4186 85.8941 92.4106L90.2439 83.4886C90.7242 82.4924 91.3113 81.5584 91.9962 80.6777L93.2149 79.1122C93.4462 78.8186 93.3394 78.3561 92.9836 78.2315C90.8309 77.4932 88.4025 78.1782 87.0149 79.9839Z"
        fill="#55FF99"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_510"
        x="0"
        y="0"
        width="153.338"
        height="162"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="30" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0.678431 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_510"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_510"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 13px;
  height: 16px;
  transform: scale(7.5);
}
</style>
