<script setup>
import SvgView30 from "@/views/home/svg/SvgView30.vue";
import t3 from "@/assets/bg/letter.svg";
import t4 from "@/assets/bg/telegram.svg";
import t5 from "@/assets/bg/discord.svg";
import t6 from "@/assets/bg/medium.svg";
import t7 from "@/assets/bg/x.svg";
import t8 from "@/assets/bg/reddit.svg";
import t9 from "@/assets/bg/telegram-2.svg";

const yearNow = new Date().getFullYear();
</script>
<template>
  <div class="footerView_root">
    <div class="footerView_root_content">
      <div class="footerView_1 footerView_root_content_title footerView-1">
        <div class="icon-container width-100">
          <div>
            <SvgView30></SvgView30>
          </div>
          <div class="fontSize">{{ $t("t65") }}</div>
        </div>
        <div class="icon-container">
          <div class="icon-row">
            <a href="https://t.me/eniac_community" target="_blank">
              <img :src="t4" title="Telegram Group" class="icon" />
            </a>
            <a href="https://t.me/eniac_official" target="_blank">
              <img :src="t9" title="Telegram Channel" class="icon" />
            </a>
            <a href="https://discord.gg/UfkCsgXzNs" target="_blank">
              <img :src="t5" title="Discord" class="icon" />
            </a>
            <a href="https://medium.com/@eniac_network" target="_blank">
              <img :src="t6" title="Medium" class="icon" />
            </a>
            <a href="https://twitter.com/eniac_network" target="_blank">
              <img :src="t7" title="X Platform" class="icon" />
            </a>
            <a href="https://www.reddit.com/r/ENIAC/" target="_blank">
              <img :src="t8" title="Reddit" class="icon" />
            </a>
          </div>
          <div class="icon-row email-row">
            <img :src="t3" title="Email Us" class="email-icon" />
            <span>contact@eniac.network</span>
          </div>
        </div>
      </div>
      <div class="d-flex f-j-sb">
        <div class="footerView_root_content_n1">
          <span>&copy; 2023-{{ yearNow }} ENIAC Labs. All rights reserved</span>
        </div>
        <!-- <div class="footerView_root_content_n2 d-flex">
          <div class="footerView_root_content_n2_email d-flex f-a-center c-p">
            <img :src="t3" alt="" />
            <span>contact@eniac.network</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped lang="less">
.width-100 {
  width: 100% !important;
}
.fontSize {
  font-size: 8px;
}
.footerView_1 {
  align-items: center;
  height: 50px;
}
.email-row {
  justify-content: start !important;
}
.footerView_root {
  padding: 72px 0 52px;
  .footerView_root_content {
    padding: 0 82px;
    color: #fff;
    .footerView_root_content_title {
      font-size: 10px;
      padding: 8px 0 14px;
    }
    .footerView-1 {
      display: flex;
      justify-content: space-between;
    }
    .footerView_root_content_n1 {
      font-size: 8px;
    }
    .footerView_root_content_n2 {
      font-size: 10px;
      gap: 17px;
    }
    .footerView_root_content_n2_email {
      gap: 16px;
      color: rgba(160, 160, 160, 1);
    }
    .footerView_root_content_n2_email:nth-of-type(1) {
      & > a > img {
        width: 12px;
        height: 12px;
      }
    }
    .icon {
      width: 12px;
      height: 12px;
    }
    .email-icon {
      width: 12px;
      height: 12px;
      padding-right: 3px;
    }
    .icon-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 30px;
      width: 25%;
    }
    .icon-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .footerView_root_content_n2_email:nth-of-type(2) {
      & > img {
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media (max-width: 576px) {
  // 从最小尺寸 576px 开始应用样式（设备的最小屏幕尺寸）
  .home_view_root {
    display: none;
  }
  .home_mobile_view_root {
    display: block;
  }
}
</style>
