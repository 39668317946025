<script setup></script>
<template>
  <svg viewBox="0 0 49 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 619">
      <path
        id="Vector"
        d="M1.22841 46.3869L24.0791 60.6972V79.2098L1.22841 46.3869Z"
        stroke="white"
        stroke-width="0.5"
      />
      <path
        id="Vector_2"
        d="M24.5781 79.1975V60.6965L47.2084 46.3905L24.5781 79.1975Z"
        stroke="white"
        stroke-width="0.5"
      />
      <path
        id="Vector 9"
        d="M24.2171 35.466L47.5774 45.5677L24.2171 60.4047L0.856772 45.5677L24.2171 35.466Z"
        stroke="white"
        stroke-width="0.5"
      />
      <path
        id="Vector_3"
        d="M0 39.2962L24.3277 0V52.6289L0 39.2962Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M24.3281 52.6289V0L48.6558 39.2962L24.3281 52.6289Z"
        fill="grey"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_153_269"
        x1="33.2"
        y1="1.29099e-08"
        x2="36.492"
        y2="52.6289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565B63" />
        <stop offset="1" stop-color="#616161" />
      </linearGradient>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 19px;
  height: 31px;
}
</style>
