<script setup></script>
<template>
  <svg viewBox="0 0 154 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_153_521)">
      <path
        d="M76.9584 59.001L93.9168 68.5072V82.2801C93.9168 89.6532 87.8737 96.8682 78.2598 98.7691L77.8577 98.8454L76.9584 99L76.0486 98.6845C66.5122 95.2964 60.3664 87.2541 60.0145 79.8948L60.003 79.5803L60 65.5444L76.9584 59V59.001ZM76.9576 62.2214L62.9064 67.6416L62.9101 79.7966C63.0594 85.7064 68.0334 92.4999 76.0077 95.6409L76.3953 95.7903L76.7889 95.9334L76.9565 95.9916L77.1174 95.9649C85.4346 94.4631 90.68 88.5444 90.9942 82.5926L91.0058 82.3091L91.0096 82.0259V70.0974L76.9576 62.2214ZM81.7137 73.4271L83.7689 75.6653L74.5185 84.1228L68.0092 77.0343L70.0647 75.1549L74.5174 80.0053L81.7137 73.4271Z"
        fill="#55FF99"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_153_521"
        x="0"
        y="0"
        width="153.916"
        height="160"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="30" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0 0.678431 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_153_521"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_153_521"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 13px;
  height: 16px;
  transform: scale(8.5);
}
</style>
