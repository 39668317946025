<script setup></script>
<template>
  <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_157_1429)">
      <path
        d="M11 34.6667H8.5L12.6667 43.3333L16.8333 34.6667H14.3333V13.3333H19.3333V0H6V13.3333H11V34.6667ZM40.8333 51.6667H19.3333V46.6667H6V60H19.3333V55H40.8333V57.5L49.3333 53.3333L40.8333 49.1667V51.6667ZM61 25.1667H63.5L59.3333 16.6667L55.1667 25.1667H57.6667V46.6667H52.6667V60H66V46.6667H61V25.1667ZM52.6667 0V5H31.3333V2.5L22.6667 6.66667L31.3333 10.8333V8.33333H52.6667V13.3333H66V0H52.6667Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_157_1429"
        x="0"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="10" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00541523 0 0 0 0 0.0342157 0 0 0 0 0.0433105 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_157_1429"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_157_1429"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 23px;
  height: 23px;
}
</style>
