<script setup></script>
<template>
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 516">
      <rect
        id="Rectangle 56"
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        fill="black"
        stroke="#42FFAD"
      />
      <g id="Clip path group">
        <mask
          id="mask0_153_134"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="7"
          y="6"
          width="16"
          height="18"
        >
          <g id="clippath">
            <path id="Vector" d="M23 6H7V23.8923H23V6Z" fill="white" />
          </g>
        </mask>
        <g mask="url(#mask0_153_134)">
          <g id="Group">
            <path
              id="Vector_2"
              d="M22.9437 9.80021C22.9247 9.25214 22.468 8.73546 21.9291 8.64084C21.9291 8.64084 19.8345 8.31362 18.659 7.89722C17.1909 7.37771 15.7386 6.24015 15.7386 6.24015C15.2849 5.9188 14.5916 5.93755 14.1696 6.28745C14.1696 6.28745 13.1425 7.31754 11.2239 7.91641C9.59795 8.56323 8.27116 8.67791 8.06122 8.69273C8.04072 8.69426 8.02045 8.696 8.00017 8.69927C7.47848 8.78342 7.03658 9.26326 7.02721 9.80043C7.02721 9.80043 6.95483 12.6138 7.04617 15.0838C7.08084 19.5042 12.3516 23.8706 15.017 23.8706C17.6823 23.8706 22.1717 20.8272 22.8838 15.1468C23.0603 11.7788 22.9437 9.80108 22.9437 9.80108V9.80021ZM20.5587 12.6953V12.6701L14.4122 18.4231C14.1342 18.6849 13.7141 18.7252 13.3915 18.5209L13.2308 18.3856L9.83427 14.8861C9.68232 14.7226 9.60209 14.505 9.61147 14.2818C9.62084 14.0588 9.71938 13.8486 9.88463 13.6986C10.23 13.384 10.7643 13.4065 11.0819 13.749L13.8733 16.6559L19.4087 11.4603C19.754 11.1458 20.2884 11.1682 20.6058 11.5107C20.7584 11.6733 20.8391 11.8902 20.8303 12.113C20.8214 12.3359 20.7235 12.5456 20.5585 12.6956L20.5587 12.6953Z"
              fill="#42FFAD"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<style lang="less" scoped>
svg {
  width: 12px;
  height: 12px;
}
</style>
